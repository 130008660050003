import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Button,
  Form,
  Table,
  Search,
  Radio
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { options: any, data: any, waitlist: any, staff: any, num: 1 | 2, print: boolean } &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplateWaitlist extends React.PureComponent<StaffProps> {
    public componentWillMount() {
      this.props.loadWaitlist(this.props.num)
    }

    public render() {
      return (
        <Container fluid>
          <Table compact celled striped style={{marginBottom:20}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}></Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>SID</Table.HeaderCell>
                <Table.HeaderCell>Facility</Table.HeaderCell>
                <Table.HeaderCell>Housing</Table.HeaderCell>
                <Table.HeaderCell>Date Added</Table.HeaderCell>
                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.waitlist.inmates.filter((waitlist:any) => this.props.waitlist.all || this.props.staff.admin ? true : this.props.staff.facilities.includes(waitlist.facilityAbbr)).map((waitlist:any, i:number) => (
                <Table.Row key={waitlist.recordID}>
                  <Table.Cell>{i+1}</Table.Cell>
                  <Table.Cell><div className='link'>{`${waitlist.lastName}, ${waitlist.firstName}`}</div></Table.Cell>
                  <Table.Cell>{waitlist.sid}</Table.Cell>
                  <Table.Cell>{waitlist.facilityAbbr}</Table.Cell>
                  <Table.Cell>{waitlist.housing}</Table.Cell>
                  <Table.Cell>{waitlist.dateAdded}</Table.Cell>
                  <Table.Cell>{this.props.print ? null : <Button negative size='mini' content='Remove' onClick={_.debounce(() => this.props.removeTemplateWaitlist(this.props.num, waitlist.recordID), 10000, true)} />}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {this.props.print ? null : <Form style={{marginBottom:10}}>
            <Form.Group style={{margin:0}}>
              <Search
                placeholder='Add Inmate'
                loading={this.props.waitlist.loading}
                onResultSelect={(e:any, data:any) => this.props.selectTemplateWaitlistInmate(this.props.num, data.result)}
                onSearchChange={(e:any, data:any) => this.props.searchTemplateInmates(this.props.num, data.value as string)}
                results={this.props.waitlist.search}
                value={this.props.waitlist.value}
                onFocus={() => this.props.clearTemplateInmates(this.props.num)}
              />
              <Button positive content='Add Inmate' onClick={_.debounce(() => this.props.addTemplateWaitlist(this.props.num), 10000, true)} style={{marginLeft:10}} />
              <label style={{marginTop:11,marginLeft:10,fontWeight:700}}>My Facilities</label>
              <Radio toggle checked={this.props.waitlist.all} onChange={(e:any, data:any) => this.props.waitlistFacilityToggle(this.props.num, data.checked)} style={{verticalAlign:'middle',fontWeight:700,marginTop:7,marginLeft:10}} />
              <label style={{marginTop:11,marginLeft:10,fontWeight:700}}>All Facilities</label>
            </Form.Group>
          </Form>}
        </Container>
      )
    }
}

export default connect(
    (state: ApplicationState, ownProps: any) => { return { data: ownProps.data, waitlist: ownProps.waitlist, options: ownProps.options, staff: state.staff.staff, num: ownProps.num, print: ownProps.print } },
    PanesStore.actionCreators
)(TemplateWaitlist as any)