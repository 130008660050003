import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as ClassesStore from '../../store/reducers/classes'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label,
  Icon,
  Dropdown,
  Button
} from 'semantic-ui-react'
const _ = require('underscore')

type ClassProps =
    { template: ClassesStore.Template, filters: ClassesStore.ClassFilters, dates: boolean, tab: string, mode: string, staff: any } &
    typeof DrawerStore.actionCreators &
    typeof ClassesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplateRow extends React.PureComponent<ClassProps> {
    public render() {
        return (
          <Table.Row id={`template${this.props.template.recordID}`}>
            <Table.Cell>{_.filter(this.props.template.facilities, (f:any) => f != null).join(', ')}</Table.Cell>
            <Table.Cell>
              {this.props.mode == 'dual' ? <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>
                  {this.props.template.className}
                  {this.props.template.favorite ? <Popup content="Template is a favorite" position="top center" trigger={<Icon name="star" color="blue" style={{marginLeft:5}} />} /> : null}
                </div>}
              >
                <Popup.Header>Open in pane</Popup.Header>
                <Popup.Content>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchTemplate(1, this.props.template.recordID) }}>1</Label>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchTemplate(2, this.props.template.recordID) }}>2</Label>
                  {this.props.template.favorite ? <Label circular icon='star' className='zero' onClick={() => { this.props.unfavoriteTemplateRow(this.props.template.recordID) }} /> : <Label circular icon='star outline' className='zero' onClick={() => { this.props.favoriteTemplateRow(this.props.template.recordID) }} />}
                </Popup.Content>
              </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, this.props.template.recordID) }}>
                {this.props.template.className}
                {this.props.template.favorite ? <Popup content="Template is a favorite" position="top center" trigger={<Icon name="star" color="blue" style={{marginLeft:5}} />} /> : null}
              </div>}
            </Table.Cell>
            <Table.Cell>
              <Dropdown
                trigger={<Label style={{width:76}}><div style={{float:'right',margin: '0'}} ><Icon name='user' style={{color: this.props.template.facilityGender == 'M' ? '#347DC1' : this.props.template.facilityGender == 'F' ? '#CC6594' : ''}} /></div><div style={{display:'inline-block',verticalAlign:'top'}}>{_.filter(this.props.template.waitlists, (waitlist:any) => this.props.filters.facility == 'all' || this.props.staff.admin ? true : this.props.filters.facility == 'my' ? this.props.staff.facilities.includes(waitlist.facilityAbbr) : this.props.filters.facility == waitlist.facilityAbbr).length} / {this.props.template.waitlists.length}</div></Label>}
                onChange={(e:any, data:any) => {  this.props.templateActionSelect(this.props.template.recordID, data) }}
                options={[{ key: 'w', value: 'waitlist', text: 'Manage waitlist' }]}
                value={''}
                icon={null}
              />
              <Popup content={`Create a new class from this template.`} position="top center" trigger={<Button circular positive icon='plus' onClick={() => this.props.toggleCreateTemplateClass(this.props.template.recordID, this.props.template.className, `${this.props.template.programName} -- ${this.props.template.courseName}`)} style={{fontSize:'0.7em',marginLeft:5}} />} />
            </Table.Cell>
            <Table.Cell>{this.props.template.fundingSource}</Table.Cell>
          </Table.Row>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { template: ownProps.template, filters: state.classes.filters, dates: state.classes.dates, tab: state.drawer.tab, mode: state.panes.mode, staff: state.staff.staff } },
  { ...DrawerStore.actionCreators, ...ClassesStore.actionCreators, ...PanesStore.actionCreators }
)(TemplateRow as any)