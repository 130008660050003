import React, { Fragment } from 'react'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'

const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Quarterly extends React.PureComponent<ReportProps> {
    public facilityTotalEnrollment(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.enrolled, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.enrolled, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalEnrollmentUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(program.inmates, (inmate:any) => {
            if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(course.inmates, (inmate:any) => {
                if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(klass.inmates, (inmate:any) => {
                  if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalEnrollmentHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalSignups(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.signups, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.signups, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.signups, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalSignupsUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(program.inmates, (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(course.inmates, (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(klass.inmates, (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalSignupsHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityCoursesTotalEnrollment(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalEnrollmentHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityCoursesTotalEnrollmentUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.inmates, (inmate:any) => {
            if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public facilityProgramsTotalEnrollment(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
      }, 0)
    }

    public facilityProgramsTotalEnrollmentHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityProgramsTotalEnrollmentUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.inmates, (inmate:any) => {
          if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public facilityCoursesTotalSignups(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.signups, 0)
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalSignupsHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityCoursesTotalSignupsUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.inmates, (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public facilityProgramsTotalSignups(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.signups, 0)
      }, 0)
    }

    public facilityProgramsTotalSignupsHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityProgramsTotalSignupsUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.inmates, (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public facilityCompletionsTotalSignups(facility:any) {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.signups, 0)
    }

    public facilityCompletionsTotalSignupsHighlight(facility:any) {
      var highlight = false
      if (_.any(this.props.report.generated.completions, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityCompletionsTotalSignupsUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.completions, (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public facilityCompletionsTotalEnrollment(facility:any) {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
    }

    public facilityCompletionsTotalEnrollmentHighlight(facility:any) {
      var highlight = false
      if (_.any(this.props.report.generated.completions, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityCompletionsTotalEnrollmentUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.completions, (inmate:any) => {
        if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public facilityTotalActive(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.active, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.active, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.active, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalActiveHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityCoursesTotalActive(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.active, 0)
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalActiveHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityProgramsTotalActive(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.active, 0)
      }, 0)
    }

    public facilityProgramsTotalActiveHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityCompletionsTotalActive(facility:any) {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.active, 0)
    }

    public facilityCompletionsTotalActiveHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityTotalSuspended(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.suspended, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.suspended, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.suspended, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalSuspendedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityCoursesTotalSuspended(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.suspended).length
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalSuspendedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityProgramsTotalSuspended(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.suspended).length
      }, 0)
    }

    public facilityProgramsTotalSuspendedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityCompletionsTotalSuspended(facility:any) {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.suspended).length
    }

    public facilityCompletionsTotalSuspendedHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityTotalQuarantined(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalQuarantinedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityCoursesTotalQuarantined(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalQuarantinedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityProgramsTotalQuarantined(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
      }, 0)
    }

    public facilityProgramsTotalQuarantinedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityCompletionsTotalQuarantined(facility:any) {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.quarantined).length
    }

    public facilityCompletionsTotalQuarantinedHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityTotalMissingHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalMissingUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalMissing(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.missing, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.missing, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityCoursesTotalMissing(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.missing).length
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalMissingHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityCoursesTotalMissingUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public facilityProgramsTotalMissing(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.missing).length
      }, 0)
    }

    public facilityProgramsTotalMissingHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityProgramsTotalMissingUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public facilityCompletionsTotalMissing(facility:any) {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing).length
    }

    public facilityCompletionsTotalMissingHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityCompletionsTotalMissingUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing > 0), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public facilityTotalEnrolledMale(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'M' ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolledMaleUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'M'), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompletedMale(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'M' ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompletedMaleUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'M' && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalEnrolledFemale(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'F' ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolledFemaleUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'F'), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompletedFemale(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'F' ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompletedFemaleUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'F' && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalEnrolledUnder25(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age < 25 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolledUnder25Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age < 25), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompletedUnder25(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age < 25 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompletedUnder25Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age < 25 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalEnrolled25to29(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 25 && inmate.age <= 29 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolled25to29Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompleted25to29(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 25 && inmate.age <= 29 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompleted25to29Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalEnrolled30to39(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 30 && inmate.age <= 39 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolled30to39Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompleted30to39(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 30 && inmate.age <= 39 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompleted30to39Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalEnrolledOver40(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 40 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolledOver40Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 40), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompletedOver40(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 40 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompletedOver40Unique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 40 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalEnrolledEthnicity(facility:any, ethnicity:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.ethnicityName == ethnicity ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalEnrolledEthnicityUnique(facility:any, ethnicity:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == ethnicity), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompletedEthnicity(facility:any, ethnicity:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.ethnicityName == ethnicity ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public facilityTotalCompletedEthnicityUnique(facility:any, ethnicity:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityTotalCompletedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalCompleted(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityCoursesTotalCompleted(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalCompletedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityCoursesTotalCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public facilityProgramsTotalCompleted(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
      }, 0)
    }

    public facilityProgramsTotalCompletedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityProgramsTotalCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public facilityCompletionsTotalCompleted(facility:any) {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.completed, 0)
    }

    public facilityCompletionsTotalCompletedHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityCompletionsTotalCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.completed > 0), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public facilityTotalNotCompleted(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityTotalNotCompletedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalNotCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public facilityCoursesTotalNotCompleted(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalNotCompletedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityCoursesTotalNotCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public facilityProgramsTotalNotCompleted(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
      }, 0)
    }

    public facilityProgramsTotalNotCompletedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityProgramsTotalNotCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public facilityCompletionsTotalNotCompleted(facility:any) {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
    }

    public facilityCompletionsTotalNotCompletedHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityCompletionsTotalNotCompletedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public facilityTotalIncompleteHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalIncompleteUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.incomplete > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.incomplete > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.incomplete > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalIncomplete(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.incomplete, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.incomplete, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.incomplete, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalContEdHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalContEdUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalContEd(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.contEd, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.contEd, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityCoursesTotalContEd(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
        }, 0)
      }, 0)
    }

    public facilityCoursesTotalContEdHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public facilityCoursesTotalContEdUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public facilityProgramsTotalContEd(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
      }, 0)
    }

    public facilityProgramsTotalContEdHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public facilityProgramsTotalContEdUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public facilityCompletionsTotalContEd(facility:any) {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
    }

    public facilityCompletionsTotalContEdHighlight(facility:any) {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public facilityCompletionsTotalContEdUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public facilityTotalDroppedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalDroppedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.dropped > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.dropped > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.dropped > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalDropped(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.dropped, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.dropped, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.dropped, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalTransferredHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalTransferredUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.transferred > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.transferred > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.transferred > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalTransferred(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.transferred, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.transferred, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.transferred, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalParoledHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalParoledUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.paroled > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.paroled > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.paroled > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalParoled(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.paroled, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.paroled, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.paroled, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalReleasedUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.released > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.released > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.released > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalReleasedHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalSchedule(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityTotalDiscipline(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityTotalCovid(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityTotalNoShow(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.noShow, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.noShow, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.noShow, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalNoShowHighlight(facility:any) {
      var highlight = false
      _.each(facility.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public facilityTotalNoShowUnique(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.noShow > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.noShow > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.noShow > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public facilityTotalReclassified(facility:any) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityEthnicityTotalEnrollment(facility:any, ethnicity:string) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
             }, 0)
         }, 0)
      }, 0)
   }

    public facilityEthnicityTotalCompleted(facility:any, ethnicity:string) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a4:number, inmate:any) => a4 + inmate.completed, 0)
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityEthnicityTotalEnrollmentUnique(facility:any, ethnicity:string) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                 return a3 + _.filter(klass.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity == ethnicity).length
             }, 0)
         }, 0)
      }, 0)
    }

    public facilityEthnicityTotalCompletedUnique(facility:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(facility.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalSignupsHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalEnrollmentHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalSignups(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.signups, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.signups, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.signups, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalEnrollment(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.enrolled, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.enrolled, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalCoursesEnrollment() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.enrolled, 0)
         }, 0)
      }, 0)
    }

    public totalCoursesSignups() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.signups, 0)
         }, 0)
      }, 0)
    }

    public totalSignupsUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(program.inmates, (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(course.inmates, (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(klass.inmates, (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalEnrollmentUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(program.inmates, (inmate:any) => {
            if (!mem[inmate.recordID] && inmate.enrolled) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(course.inmates, (inmate:any) => {
                if (!mem[inmate.recordID] && inmate.enrolled) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(klass.inmates, (inmate:any) => {
                  if (!mem[inmate.recordID] && inmate.enrolled) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalCoursesEnrollmentHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesEnrollmentUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.inmates, (inmate:any) => {
            if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public totalCoursesSignupsHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesSignupsUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.inmates, (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }


    public totalProgramsEnrollment() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a3:number, inmate:any) => a3 + inmate.enrolled, 0)
      }, 0)
    }

    public totalProgramsEnrollmentHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsEnrollmentUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.inmates, (inmate:any) => {
          if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public totalProgramsSignups() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a3:number, inmate:any) => a3 + inmate.signups, 0)
      }, 0)
    }

    public totalProgramsSignupsHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsSignupsUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.inmates, (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public totalCompletionsEnrollment() {
      return _.reduce(this.props.report.generated.completions, (a:number, inmate:any) => a + inmate.enrolled, 0)
    }

    public totalCompletionsEnrollmentHighlight() {
      var highlight = false
      if (_.any(this.props.report.generated.completions, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsEnrollmentUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.completions, (inmate:any) => {
        if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public totalCompletionsSignups() {
      return _.reduce(this.props.report.generated.completions, (a:number, inmate:any) => a + inmate.signups, 0)
    }

    public totalCompletionsSignupsHighlight() {
      var highlight = false
      if (_.any(this.props.report.generated.completions, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsSignupsUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.completions, (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }


    public totalProgramEnrollmentHighlight(data:any) {
      var highlight = false
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalProgramEnrollmentUnique(data:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(klass.inmates, (inmate:any) => {
              if (inmate.enrolled && !mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalActive(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.active, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.active, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.active, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalActiveHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalCoursesActive() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.active, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesActiveHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalProgramsActive() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.active, 0)
      }, 0)
    }

    public totalProgramsActiveHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalCompletionsActive() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.active, 0)
    }

    public totalCompletionsActiveHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalSuspended(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.filter(program.inmates, (inmate:any) => inmate.suspended).length
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.filter(course.inmates, (inmate:any) => inmate.suspended).length
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.filter(klass.inmates, (inmate:any) => inmate.suspended).length
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalQuarantined(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalSuspendedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalCoursesSuspended() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.suspended).length
        }, 0)
      }, 0)
    }

    public totalCoursesSuspendedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalProgramsSuspended() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.suspended).length
      }, 0)
    }

    public totalProgramsSuspendedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalCompletionsSuspended() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.suspended).length
    }

    public totalCompletionsSuspendedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalQuarantinedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalCoursesQuarantined() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
        }, 0)
      }, 0)
    }

    public totalCoursesQuarantinedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalProgramsQuarantined() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
      }, 0)
    }

    public totalProgramsQuarantinedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalCompletionsQuarantined() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.quarantined).length
    }

    public totalCompletionsQuarantinedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalMissingUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalMissing(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.missing, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.missing, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalCompleted(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalMissingHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalCoursesMissing() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesMissingHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesMissingUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public totalProgramsMissing() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
      }, 0)
    }

    public totalProgramsMissingHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsMissingUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => inmate.missing > 0), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public totalCompletionsMissing() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.missing, 0)
    }

    public totalCompletionsMissingHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsMissingUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing > 0), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public totalCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalCoursesCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public totalProgramsCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
      }, 0)
    }

    public totalProgramsCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public totalCompletionsCompleted() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.completed, 0)
    }

    public totalCompletionsCompletedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.completed > 0), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public totalProgramCompletedHighlight(data:any) {
      var highlight = false
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalProgramCompletedUnique(data:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalPopulation() {
      return this.props.report.generated.population.length
    }

    public totalPopulationMale() {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.sex == 'M').length
    }

    public totalPopulationFemale() {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.sex == 'F').length
    }

    public totalPopulationUnder25() {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.age < 25).length
    }

    public totalPopulation25to29() {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.age >= 25 && inmate.age <= 29).length
    }

    public totalPopulation30to39() {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.age >= 30 && inmate.age <= 39).length
    }

    public totalPopulationOver40() {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.age >= 40).length
    }

    public totalPopulationEthnicity(ethnicity:any) {
      return _.filter(this.props.report.generated.population, (inmate:any) => inmate.ethnicityName == ethnicity).length
    }

    public facilityTotalPopulation(facility:any) {
      return this.props.report.generated.population.length
    }

    public facilityTotalPopulationMale(facility:any) {
      return _.filter(facility.population, (inmate:any) => inmate.sex == 'M').length
    }

    public facilityTotalPopulationFemale(facility:any) {
      return _.filter(facility.population, (inmate:any) => inmate.sex == 'F').length
    }

    public facilityTotalPopulationUnder25(facility:any) {
      return _.filter(facility.population, (inmate:any) => inmate.age < 25).length
    }

    public facilityTotalPopulation25to29(facility:any) {
      return _.filter(facility.population, (inmate:any) => inmate.age >= 25 && inmate.age <= 29).length
    }

    public facilityTotalPopulation30to39(facility:any) {
      return _.filter(facility.population, (inmate:any) => inmate.age >= 30 && inmate.age <= 39).length
    }

    public facilityTotalPopulationOver40(facility:any) {
      return _.filter(facility.population, (inmate:any) => inmate.age >= 40).length
    }

    public facilityTotalPopulationEthnicity(facility:any, ethnicity:any) {
      return _.filter(facility.population, (inmate:any) => inmate.ethnicityName == ethnicity).length
    }

    public totalEnrolledMale() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'M' ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolledMaleUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'M'), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompletedMale() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'M' ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompletedMaleUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'M' && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalEnrolledFemale() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'F' ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolledFemaleUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'F'), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompletedFemale() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.sex == 'F' ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompletedFemaleUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.sex == 'F' && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalEnrolledUnder25() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age < 25 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolledUnder25Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age < 25), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompletedUnder25() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age < 25 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompletedUnder25Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age < 25 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalEnrolled25to29() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 25 && inmate.age <= 29 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolled25to29Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompleted25to29() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 25 && inmate.age <= 29 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompleted25to29Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalEnrolled30to39() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 30 && inmate.age <= 39 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolled30to39Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompleted30to39() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 30 && inmate.age <= 39 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompleted30to39Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalEnrolledOver40() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 40 ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolledOver40Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 40), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompletedOver40() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.age >= 40 ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompletedOver40Unique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.age >= 40 && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalEnrolledEthnicity(ethnicity:any) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.ethnicityName == ethnicity ? (inmate.enrolled) : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEnrolledEthnicityUnique(ethnicity:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == ethnicity), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCompletedEthnicity(ethnicity:any) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + (inmate.ethnicityName == ethnicity ? inmate.completed : 0), 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCompletedEthnicityUnique(ethnicity:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalNotCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalNotCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalNotCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalCoursesNotCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesNotCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesNotCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public totalProgramsNotCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
      }, 0)
    }

    public totalProgramsNotCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsNotCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public totalCompletionsNotCompleted() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
    }

    public totalCompletionsNotCompletedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsNotCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public totalIncompleteHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalIncompleteUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.incomplete > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.incomplete > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.incomplete > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalIncomplete(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.incomplete, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.incomplete, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.incomplete, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalContEdHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalContEdUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalContEd(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.contEd, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.contEd, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalCoursesContEd() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesContEdHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesContEdUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(_.filter(course.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        })
      })
      return inmates
    }

    public totalProgramsContEd() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
      }, 0)
    }

    public totalProgramsContEdHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsContEdUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(_.filter(program.inmates, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
          if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
        })
      })
      return inmates
    }

    public totalCompletionsContEd() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
    }

    public totalCompletionsContEdHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsContEdUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.contEd > 0), (inmate:any) => {
        if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
      })
      return inmates
    }

    public totalDroppedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalDroppedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.dropped > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.dropped > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.dropped > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalDropped(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.dropped, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.dropped, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.dropped, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalTransferredHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalTransferredUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.transferred > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.transferred > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.transferred > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalTransferred(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.transferred, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.transferred, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.transferred, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalParoledHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalParoledUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.paroled > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.paroled > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.paroled > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalParoled(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.paroled, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.paroled, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.paroled, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalReleasedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalReleasedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.released > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.released > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.released > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalReleased(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.released, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.released, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.released, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public facilityTotalReleased(facility:any, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(facility.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.released, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.released, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.released, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalSchedule() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalDiscipline() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCovid() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalNoShowHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
             _.each(course.classes, (klass:any) => {
                 if (_.any(_.filter(klass.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
                    highlight = true
                 }
             })
         })
      })
      return highlight
    }

    public totalNoShowUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.noShow > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.noShow > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.noShow > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalNoShow(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.noShow, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.noShow, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.noShow, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalReclassified() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalEthnicityEnrollment(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a2:number, inmate:any) => a2 + inmate.enrolled, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a3:number, inmate:any) => a3 + inmate.enrolled, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalEthnicityEnrollmentUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity == ethnicity), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity == ethnicity), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity == ethnicity), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalEthnicityCompleted(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalEthnicityCompletedUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalNonEthnicityEnrollment(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a2:number, inmate:any) => a2 + inmate.enrolled, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a3:number, inmate:any) => a3 + inmate.enrolled, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a4:number, inmate:any) => a4 + inmate.enrolled, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalNonEthnicityEnrollmentUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity != ethnicity), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity != ethnicity), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity != ethnicity), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalNonEthnicityCompleted(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalNonEthnicityCompletedUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalProgramEthnicityEnrollmentUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity == ethnicity), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramEthnicityCompletedUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramNonEthnicityEnrollmentUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.enrolled && inmate.ethnicity != ethnicity), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramNonEthnicityCompletedUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public render() {
      return (
        <Fragment>
          <html lang="en">
            <head>
              <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
            </head>
            <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
              return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
            }`}} />
            <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
              {this.report()}
            </body>
          </html>
        </Fragment>
      )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let num = 0

        switch (params.reportType) {
        case 'detailed':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      {params.showDates ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Start Date</span></div></Table.HeaderCell> : null}
                      {params.showDates ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>End Date</span></div></Table.HeaderCell> : null}
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Dropped</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Transferred</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Paroled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Released</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>No Show</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.classes.map((klass:any, t:number) => {
                          num += 1
                          var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                          var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var signupsUnique = klass.inmates.length
                          var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                          var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                          var active = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                          var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var suspended = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                          var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                          var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                          var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                          var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                          var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                          var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                          var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var incompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.incomplete > 0).length
                          var dropped = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                          var droppedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var droppedUnique = _.filter(klass.inmates, (inmate:any) => inmate.dropped > 0).length
                          var transferred = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                          var transferredHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var transferredUnique = _.filter(klass.inmates, (inmate:any) => inmate.transferred > 0).length
                          var paroled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                          var paroledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var paroledUnique = _.filter(klass.inmates, (inmate:any) => inmate.paroled > 0).length
                          var noShow = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                          var noShowHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var noShowUnique = _.filter(klass.inmates, (inmate:any) => inmate.noShow > 0).length
                          var released = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                          var releasedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var releasedUnique = _.filter(klass.inmates, (inmate:any) => inmate.released > 0).length
                          var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                          var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                          return (
                            <Fragment key={klass.className}>
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                <Table.Cell />
                                {params.showDates ? <Table.Cell /> : null}
                                {params.showDates ? <Table.Cell /> : null}
                                {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                              {klass.inmates.map((inmate:any, i:number) => {
                                if (params.showDates) {
                                  inmate.dates.map((date:any, d:number) => {
                                    if (d == 0) {
                                      num += 1
                                      return (
                                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={d}>
                                          <Table.Cell />
                                          <Table.Cell />
                                          <Table.Cell />
                                          <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight}><div style={{whiteSpace:'nowrap'}}>{date.startDate}</div></Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight}><div style={{whiteSpace:'nowrap'}}>{date.endDate}</div></Table.Cell>
                                          {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                          <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.droppedHighlight} style={{textAlign:'right'}}><b>{inmate.dropped ? inmate.dropped : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.transferredHighlight} style={{textAlign:'right'}}><b>{inmate.transferred ? inmate.transferred : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.paroledHighlight} style={{textAlign:'right'}}><b>{inmate.paroled ? inmate.paroled : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.releasedHighlight} style={{textAlign:'right'}}><b>{inmate.released ? inmate.released : ''}</b></Table.Cell>
                                          <Table.Cell warning={inmate.noShowHighlight} style={{textAlign:'right'}}><b>{inmate.noShow ? inmate.noShow : ''}</b></Table.Cell>
                                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                        </Table.Row>
                                      )
                                    } else {
                                      num += 1
                                      return (
                                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={d}>
                                          <Table.Cell colSpan={4} />
                                          <Table.Cell warning={inmate.inmateHighlight}><div style={{whiteSpace:'nowrap'}}>{date.startDate}</div></Table.Cell>
                                          <Table.Cell warning={inmate.inmateHighlight}><div style={{whiteSpace:'nowrap'}}>{date.endDate}</div></Table.Cell>
                                          <Table.Cell colSpan={params.showSignups ? 14 : 13} />
                                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                        </Table.Row>
                                      )
                                    }
                                  })
                                } else {
                                  num += 1
                                  return (
                                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                      <Table.Cell />
                                      <Table.Cell />
                                      <Table.Cell />
                                      <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                      {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                      <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.droppedHighlight} style={{textAlign:'right'}}><b>{inmate.dropped ? inmate.dropped : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.transferredHighlight} style={{textAlign:'right'}}><b>{inmate.transferred ? inmate.transferred : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.paroledHighlight} style={{textAlign:'right'}}><b>{inmate.paroled ? inmate.paroled : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.releasedHighlight} style={{textAlign:'right'}}><b>{inmate.released ? inmate.released : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.noShowHighlight} style={{textAlign:'right'}}><b>{inmate.noShow ? inmate.noShow : ''}</b></Table.Cell>
                                      <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                    </Table.Row>
                                  )
                                }
                              })}
                            </Fragment>
                          )
                        })
                      )
                    )}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={params.showDates ? 6 : 4} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalSignupsUnique()}</b> : <b>{this.totalSignups()}{this.totalSignupsUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalSignupsUnique()})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique()}</b> : <b>{this.totalEnrollment()}{this.totalEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalActive()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalSuspended()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalQuarantined()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalMissingUnique()}</b> : <b>{this.totalMissing()}{this.totalMissingUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalMissingUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique()}</b> : <b>{this.totalCompleted()}{this.totalCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalIncomplete()}{this.totalIncompleteUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalIncompleteUnique()})</span>}</b></div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalContEdUnique()}</b> : <b>{this.totalContEd()}{this.totalContEdUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalContEdUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalDroppedUnique()}</b> : <b>{this.totalDropped()}{this.totalDroppedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalDroppedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalTransferredUnique()}</b> : <b>{this.totalTransferred()}{this.totalTransferredUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalTransferredUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalParoledUnique()}</b> : <b>{this.totalParoled()}{this.totalParoledUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalParoledUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalReleasedUnique()}</b> : <b>{this.totalReleased()}{this.totalReleasedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalReleasedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalNoShowUnique()}</b> : <b>{this.totalNoShow()}{this.totalNoShowUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalNoShowUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                  <Table key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                        {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Dropped</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Transferred</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Paroled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Released</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>No Show</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell colSpan={params.showSignups ? 18 : 17} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.classes.map((klass:any, t:number) => {
                            num += 1
                            var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                            var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var signupsUnique = klass.inmates.length
                            var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                            var active = _.filter(klass.inmates, (inmate:any) => inmate.active).length
                            var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight || inmate.inmateHighlight)
                            var suspended = _.filter(klass.inmates, (inmate:any) => inmate.suspended).length
                            var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                            var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                            var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                            var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                            var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                            var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                            var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var incompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.incomplete > 0).length
                            var dropped = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                            var droppedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var droppedUnique = _.filter(klass.inmates, (inmate:any) => inmate.dropped > 0).length
                            var transferred = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                            var transferredHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var transferredUnique = _.filter(klass.inmates, (inmate:any) => inmate.transferred > 0).length
                            var paroled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                            var paroledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var paroledUnique = _.filter(klass.inmates, (inmate:any) => inmate.paroled > 0).length
                            var noShow = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                            var noShowHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var noShowUnique = _.filter(klass.inmates, (inmate:any) => inmate.noShow > 0).length
                            var released = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                            var releasedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var releasedUnique = _.filter(klass.inmates, (inmate:any) => inmate.released > 0).length
                            var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                            var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                            return (
                              <Fragment key={klass.className}>
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                  <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                  <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                  <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                  <Table.Cell />
                                  {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                  <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                  <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                  <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                  <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                                {klass.inmates.map((inmate:any, i:number) => {
                                  num += 1
                                  return (
                                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                      <Table.Cell />
                                      <Table.Cell />
                                      <Table.Cell />
                                      <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                      {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell> : null}
                                      <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.droppedHighlight} style={{textAlign:'right'}}><b>{inmate.dropped ? inmate.dropped : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.transferredHighlight} style={{textAlign:'right'}}><b>{inmate.transferred ? inmate.transferred : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.paroledHighlight} style={{textAlign:'right'}}><b>{inmate.paroled ? inmate.paroled : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.releasedHighlight} style={{textAlign:'right'}}><b>{inmate.released ? inmate.released : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.noShowHighlight} style={{textAlign:'right'}}><b>{inmate.noShow ? inmate.noShow : ''}</b></Table.Cell>
                                      <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                    </Table.Row>
                                  )
                                })}
                              </Fragment>
                            )
                          })
                        )
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={4} style={{textAlign:'right'}}>Totals</Table.Cell>
                            {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalSignupsUnique(facility)}</b> : <b>{this.facilityTotalSignups(facility)}{this.facilityTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalActive(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalSuspended(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalQuarantined(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalMissingUnique(facility)}</b> : <b>{this.facilityTotalMissing(facility)}{this.facilityTotalMissingUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalMissingUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility)}</b> : <b>{this.facilityTotalCompleted(facility)}{this.facilityTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalIncomplete(facility)}{this.facilityTotalIncompleteUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalIncompleteUnique(facility)})</span>}</b></div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalContEdUnique(facility)}</b> : <b>{this.facilityTotalContEd(facility)}{this.facilityTotalContEdUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalContEdUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalDroppedUnique(facility)}</b> : <b>{this.facilityTotalDropped(facility)}{this.facilityTotalDroppedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalDroppedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalTransferredUnique(facility)}</b> : <b>{this.facilityTotalTransferred(facility)}{this.facilityTotalTransferredUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalTransferredUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique = 'true' ? <b>{this.facilityTotalParoledUnique(facility)}</b> : <b>{this.facilityTotalParoled(facility)}{this.facilityTotalParoledUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalParoledUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalReleasedUnique(facility)}</b> : <b>{this.facilityTotalReleased(facility)}{this.facilityTotalReleasedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalReleasedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalNoShowUnique(facility)}</b> : <b>{this.facilityTotalNoShow(facility)}{this.facilityTotalNoShowUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalNoShowUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'standard':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show courses:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showCourses == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show classes:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showClasses == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    {params.showCourses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell> : null}
                    {params.showCourses == 'true' && params.showClasses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell> : null}
                    {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Dropped</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Transferred</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Paroled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Released</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>No Show</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.programs.map((program:any, p:number) => {
                    if (!params.showCourses) {
                      num += 1
                      var signups = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                      var signupsHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var signupsUnique = program.inmates.length
                      var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                      var enrolledHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var enrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.enrolled > 0).length
                      var active = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                      var activeUnique = _.filter(program.inmates, (inmate:any) => inmate.active > 0).length
                      var activeHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var suspended = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                      var suspendedUnique = _.filter(program.inmates, (inmate:any) => inmate.suspended > 0).length
                      var suspendedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var quarantined = _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
                      var quarantinedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var missing = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                      var missingHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var missingUnique = _.filter(program.inmates, (inmate:any) => inmate.missing > 0).length
                      var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                      var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                      var incompleted = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                      var incompletedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var incompletedUnique = _.filter(program.inmates, (inmate:any) => inmate.incomplete > 0).length
                      var dropped = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                      var droppedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var droppedUnique = _.filter(program.inmates, (inmate:any) => inmate.dropped > 0).length
                      var transferred = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                      var transferredHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var transferredUnique = _.filter(program.inmates, (inmate:any) => inmate.transferred > 0).length
                      var paroled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                      var paroledHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var paroledUnique = _.filter(program.inmates, (inmate:any) => inmate.paroled > 0).length
                      var noShow = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                      var noShowHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var noShowUnique = _.filter(program.inmates, (inmate:any) => inmate.noShow > 0).length
                      var released = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                      var releasedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var releasedUnique = _.filter(program.inmates, (inmate:any) => inmate.released > 0).length
                      var contEd = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                      var contEdHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var contEdUnique = _.filter(program.inmates, (inmate:any) => inmate.contEd > 0).length
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                          <Table.Cell warning={program.highlight}>{program.programName}</Table.Cell>
                          {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{activeUnique}</b> : <b>{active}{activeUnique == 0 ? null : <span style={{fontSize:10}}> ({activeUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{suspendedUnique}</b> : <b>{suspended}{suspendedUnique == 0 ? null : <span style={{fontSize:10}}> ({suspendedUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                          <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    } else {
                      return (
                        program.courses.map((course:any, c:number) => {
                          if (!params.showClasses) {
                            num += 1
                            var signups = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                            var signupsHighlight = _.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var signupsUnique = course.inmates.length
                            var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            var enrolledHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var enrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.enrolled > 0).length
                            var active = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                            var activeUnique = _.filter(course.inmates, (inmate:any) => inmate.active > 0).length
                            var activeHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var suspended = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                            var suspendedUnique = _.filter(course.inmates, (inmate:any) => inmate.suspended > 0).length
                            var suspendedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var quarantined = _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
                            var quarantinedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var missing = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                            var missingHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var missingUnique = _.filter(course.inmates, (inmate:any) => inmate.missing > 0).length
                            var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                            var completedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                            var incompleted = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                            var incompletedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var incompletedUnique = _.filter(course.inmates, (inmate:any) => inmate.incomplete > 0).length
                            var dropped = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                            var droppedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var droppedUnique = _.filter(course.inmates, (inmate:any) => inmate.dropped > 0).length
                            var transferred = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                            var transferredHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var transferredUnique = _.filter(course.inmates, (inmate:any) => inmate.transferred > 0).length
                            var paroled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                            var paroledHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var paroledUnique = _.filter(course.inmates, (inmate:any) => inmate.paroled > 0).length
                            var noShow = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                            var noShowHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var noShowUnique = _.filter(course.inmates, (inmate:any) => inmate.noShow > 0).length
                            var released = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                            var releasedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var releasedUnique = _.filter(course.inmates, (inmate:any) => inmate.released > 0).length
                            var contEd = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                            var contEdHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var contEdUnique = _.filter(course.inmates, (inmate:any) => inmate.contEd > 0).length
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={c}>
                                <Table.Cell warning={program.highlight}>{c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{course.courseName}</Table.Cell>
                                {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{activeUnique}</b> : <b>{active}{activeUnique == 0 ? null : <span style={{fontSize:10}}> ({activeUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{suspendedUnique}</b> : <b>{suspended}{suspendedUnique == 0 ? null : <span style={{fontSize:10}}> ({suspendedUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          } else {
                            return (
                              course.classes.map((klass:any, t:number) => {
                                num += 1
                                var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                                var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var signupsUnique = klass.inmates.length
                                var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                                var active = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                                var activeUnique = _.filter(course.inmates, (inmate:any) => inmate.active > 0).length
                                var activeHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var suspended = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                                var suspendedUnique = _.filter(course.inmates, (inmate:any) => inmate.suspended > 0).length
                                var suspendedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                                var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                                var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                                var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                                var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                                var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                                var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var incompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.incomplete > 0).length
                                var dropped = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                                var droppedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var droppedUnique = _.filter(klass.inmates, (inmate:any) => inmate.dropped > 0).length
                                var transferred = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                                var transferredHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var transferredUnique = _.filter(klass.inmates, (inmate:any) => inmate.transferred > 0).length
                                var paroled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                                var paroledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var paroledUnique = _.filter(klass.inmates, (inmate:any) => inmate.paroled > 0).length
                                var noShow = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                                var noShowHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var noShowUnique = _.filter(klass.inmates, (inmate:any) => inmate.noShow > 0).length
                                var released = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                                var releasedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var releasedUnique = _.filter(klass.inmates, (inmate:any) => inmate.released > 0).length
                                var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                                var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                                return (
                                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                                    <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                    <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                    <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                    {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                    <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{activeUnique}</b> : <b>{active}{activeUnique == 0 ? null : <span style={{fontSize:10}}> ({activeUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{suspendedUnique}</b> : <b>{suspended}{suspendedUnique == 0 ? null : <span style={{fontSize:10}}> ({suspendedUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                    <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                  </Table.Row>
                                )
                              })
                            )
                          }
                        })
                      )
                    }
                  })}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>Totals</Table.Cell>
                        {params.showSignups ? <Table.Cell warning={this.totalSignupsHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalSignups(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell> : null}
                        <Table.Cell warning={this.totalEnrollmentHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalActiveHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalActive(params.showCourses ? true  : false, params.showClasses ? true : false)}</b></div></Table.Cell>
                        <Table.Cell warning={this.totalSuspendedHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalSuspended(params.showCourses ? true  : false, params.showClasses ? true : false)}</b></div></Table.Cell>
                        <Table.Cell warning={this.totalQuarantinedHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalQuarantined(params.showCourses ? true  : false, params.showClasses ? true : false)}</b></div></Table.Cell>
                        <Table.Cell warning={this.totalMissingHighlight()} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalMissingUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalMissing(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalMissingUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalMissingUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalCompletedHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalIncompleteHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalIncompleteUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalIncomplete(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalIncompleteUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalIncompleteUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalContEdHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalContEdUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalContEd(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalContEdUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalContEdUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalDroppedHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalDroppedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalDropped(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalDroppedUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalDroppedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalTransferredHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalTransferredUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalTransferred(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalTransferredUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalTransferredUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalParoledHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalParoledUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalParoled(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalParoledUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalParoledUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalReleasedHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalReleasedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalReleased(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalReleasedUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalReleasedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalNoShowHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalNoShowUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalNoShow(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalNoShowUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalNoShowUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                </Table.Body>
              </Table>
              {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                <Table key={facility.facilityAbbr}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      {params.showCourses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell> : null}
                      {params.showCourses == 'true' && params.showClasses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell> : null}
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Dropped</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Transferred</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Paroled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Released</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>No Show</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan={params.showSignups ? 17 : 16} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {facility.programs.map((program:any, p:number) => {
                      if (!params.showCourses) {
                        num += 1
                        var signups = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                        var signupsHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var signupsUnique = program.inmates.length
                        var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.enrolled > 0).length
                        var active = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                        var activeHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var suspended = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                        var suspendedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var quarantined = _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
                        var quarantinedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missing = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                        var missingHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missingUnique = _.filter(program.inmates, (inmate:any) => inmate.missing > 0).length
                        var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                        var incompleted = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                        var incompletedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var incompletedUnique = _.filter(program.inmates, (inmate:any) => inmate.incomplete > 0).length
                        var dropped = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                        var droppedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var droppedUnique = _.filter(program.inmates, (inmate:any) => inmate.dropped > 0).length
                        var transferred = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                        var transferredHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var transferredUnique = _.filter(program.inmates, (inmate:any) => inmate.transferred > 0).length
                        var paroled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                        var paroledHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var paroledUnique = _.filter(program.inmates, (inmate:any) => inmate.paroled > 0).length
                        var noShow = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                        var noShowHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var noShowUnique = _.filter(program.inmates, (inmate:any) => inmate.noShow > 0).length
                        var released = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                        var releasedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var releasedUnique = _.filter(program.inmates, (inmate:any) => inmate.released > 0).length
                        var contEd = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                        var contEdHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var contEdUnique = _.filter(program.inmates, (inmate:any) => inmate.contEd > 0).length
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                            <Table.Cell warning={program.highlight}>{program.programName}</Table.Cell>
                            {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                            <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                            <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                            <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      } else {
                        return (
                          program.courses.map((course:any, c:number) => {
                            if (!params.showClasses) {
                              num += 1
                              var signups = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                              var signupsHighlight = _.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var signupsUnique = course.inmates.length
                              var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                              var enrolledHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var enrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.enrolled > 0).length
                              var active = _.filter(course.inmates, (inmate:any) => inmate.active).length
                              var activeHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var suspended = _.filter(course.inmates, (inmate:any) => inmate.suspended).length
                              var suspendedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var quarantined = _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
                              var quarantinedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var missing = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                              var missingHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var missingUnique = _.filter(course.inmates, (inmate:any) => inmate.missing > 0).length
                              var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                              var completedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                              var incompleted = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                              var incompletedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var incompletedUnique = _.filter(course.inmates, (inmate:any) => inmate.incomplete > 0).length
                              var dropped = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                              var droppedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var droppedUnique = _.filter(course.inmates, (inmate:any) => inmate.dropped > 0).length
                              var transferred = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                              var transferredHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var transferredUnique = _.filter(course.inmates, (inmate:any) => inmate.transferred > 0).length
                              var paroled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                              var paroledHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var paroledUnique = _.filter(course.inmates, (inmate:any) => inmate.paroled > 0).length
                              var noShow = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                              var noShowHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var noShowUnique = _.filter(course.inmates, (inmate:any) => inmate.noShow > 0).length
                              var released = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                              var releasedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var releasedUnique = _.filter(course.inmates, (inmate:any) => inmate.released > 0).length
                              var contEd = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                              var contEdHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var contEdUnique = _.filter(course.inmates, (inmate:any) => inmate.contEd > 0).length
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={c}>
                                  <Table.Cell warning={program.highlight}>{c == 0 ? program.programName : ""}</Table.Cell>
                                  <Table.Cell warning={course.highlight}>{course.courseName}</Table.Cell>
                                  {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                  <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                  <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                  <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                  <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            } else {
                              return (
                                course.classes.map((klass:any, t:number) => {
                                  num += 1
                                  var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                                  var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var signupsUnique = klass.inmates.length
                                  var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                  var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                                  var active = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                                  var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var suspended = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                                  var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                                  var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                                  var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                                  var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                                  var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                                  var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete, 0)
                                  var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.incomplete), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var incompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.incomplete > 0).length
                                  var dropped = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.dropped, 0)
                                  var droppedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.dropped), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var droppedUnique = _.filter(klass.inmates, (inmate:any) => inmate.dropped > 0).length
                                  var transferred = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.transferred, 0)
                                  var transferredHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.transferred), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var transferredUnique = _.filter(klass.inmates, (inmate:any) => inmate.transferred > 0).length
                                  var paroled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.paroled, 0)
                                  var paroledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.paroled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var paroledUnique = _.filter(klass.inmates, (inmate:any) => inmate.paroled > 0).length
                                  var noShow = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.noShow, 0)
                                  var noShowHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.noShow), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var noShowUnique = _.filter(klass.inmates, (inmate:any) => inmate.noShow > 0).length
                                  var released = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.released, 0)
                                  var releasedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.released), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var releasedUnique = _.filter(klass.inmates, (inmate:any) => inmate.released > 0).length
                                  var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                                  var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                  var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                                  return (
                                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                                      <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                      <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                      <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                      {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                      <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                      <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                      <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                      <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                      <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                      <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                      <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell warning={droppedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{droppedUnique}</div> : <div>{dropped}{droppedUnique == 0 ? null : <span style={{fontSize:10}}> ({droppedUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell warning={transferredHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{transferredUnique}</div> : <div>{transferred}{transferredUnique == 0 ? null : <span style={{fontSize:10}}> ({transferredUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell warning={paroledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{paroledUnique}</div> : <div>{paroled}{paroledUnique == 0 ? null : <span style={{fontSize:10}}> ({paroledUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell warning={releasedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{releasedUnique}</div> : <div>{released}{releasedUnique == 0 ? null : <span style={{fontSize:10}}> ({releasedUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell warning={noShowHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{noShowUnique}</div> : <div>{noShow}{noShowUnique == 0 ? null : <span style={{fontSize:10}}> ({noShowUnique})</span>}</div>}</div></Table.Cell>
                                      <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                    </Table.Row>
                                  )
                                })
                              )
                            }
                          })
                        )
                      }
                    })}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalSignupsUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalSignups(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalSignupsUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalSignupsUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalEnrollment(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalEnrollmentUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalActive(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalSuspended(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalQuarantined(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalMissingUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalMissing(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalMissingUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalMissingUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalCompleted(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalCompletedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalIncompleteUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalIncomplete(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalIncompleteUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalIncompleteUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalContEdUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalContEd(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalContEdUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalContEdUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalDropped(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalDropped(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalDroppedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalDroppedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalTransferredUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalTransferred(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalTransferredUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalTransferredUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique = 'true' ? <b>{this.facilityTotalParoledUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalParoled(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalParoledUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalParoledUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalReleasedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalReleased(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalReleasedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalReleasedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalNoShowUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalNoShow(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalNoShowUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalNoShowUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
              ))}
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'completions':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                    {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.programs.map((program:any, p:number) =>
                    program.courses.map((course:any, c:number) =>
                      course.classes.map((klass:any, t:number) => {
                        num += 1
                        var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                        var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var signupsUnique = klass.inmates.length
                        var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                        var active = _.filter(klass.inmates, (inmate:any) => inmate.active).length
                        var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var suspended = _.filter(klass.inmates, (inmate:any) => inmate.suspended).length
                        var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                        var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                        var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                        var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var incompletedUnique = _.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                        var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                        var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                        var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                        var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                            <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                            <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                            <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                            {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                            <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                            <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                            <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={contEdHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</b></div></Table.Cell>
                            <Table.Cell warning={incompletedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</b></div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })
                    )
                  )}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                        {params.showSignups ? <Table.Cell warning={this.totalSignupsHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalSignups(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell> : null}
                        <Table.Cell warning={this.totalEnrollmentHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique()}</b> : <b>{this.totalEnrollment()}{this.totalEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalActiveHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalActive()}</b></div></Table.Cell>
                        <Table.Cell warning={this.totalSuspendedHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalSuspended()}</b></div></Table.Cell>
                        <Table.Cell warning={this.totalQuarantinedHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalQuarantined()}</b></div></Table.Cell>
                        <Table.Cell warning={this.totalMissingHighlight()} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalMissingUnique()}</b> : <b>{this.totalMissing()}{this.totalMissingUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalMissingUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalCompletedHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique()}</b> : <b>{this.totalCompleted()}{this.totalCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalContEdHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalContEdUnique()}</b> : <b>{this.totalContEd()}{this.totalContEdUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalContEdUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalNotCompletedHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalNotCompletedUnique()}</b> : <b>{this.totalNotCompleted()}{this.totalNotCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalNotCompletedUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                </Table.Body>
              </Table>
              {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                <Table key={facility.facilityAbbr}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={params.showSignups ? 11 : 10} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {facility.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.classes.map((klass:any, t:number) => {
                          num += 1
                          var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                          var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var signupsUnique = klass.inmates.length
                          var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                          var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                          var active = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                          var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var suspended = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                          var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                          var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                          var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                          var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                          var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var incompletedUnique = _.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                          var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                          var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                          var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                          var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                              <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                              <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                              <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                              {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                              <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                              <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                              <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                              <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={contEdHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</b></div></Table.Cell>
                              <Table.Cell warning={incompletedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</b></div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                          )
                        })
                      )
                    )}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell warning={this.facilityTotalSignupsHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalSignupsUnique(facility)}</b> : <b>{this.facilityTotalSignups(facility)}{this.facilityTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell warning={this.facilityTotalEnrollmentHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalActiveHighlight(facility)} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalActive(facility)}</b></div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalSuspendedHighlight(facility)} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalSuspended(facility)}</b></div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalQuarantinedHighlight(facility)} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalQuarantined(facility)}</b></div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalMissingHighlight(facility)} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalMissingUnique(facility)}</b> : <b>{this.facilityTotalMissing(facility)}{this.facilityTotalMissingUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalMissingUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalCompletedHighlight(facility)} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility)}</b> : <b>{this.facilityTotalCompleted(facility)}{this.facilityTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalContEdHighlight(facility)} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalContEdUnique(facility)}</b> : <b>{this.facilityTotalContEd(facility)}{this.facilityTotalContEdUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalContEdUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalNotCompletedHighlight(facility)} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalNotCompleted(facility)}</b> : <b>{this.facilityTotalNotCompleted(facility)}{this.facilityTotalNotCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalNotCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
              ))}
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'detailed-completions':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Ethnicity:</div><div style={{display:'inline-block',fontWeight:400}}>{_.any(options.ethnicities, (e:any) => e.value == params.ethnicity) ? _.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text : 'All'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showPrograms == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show courses:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showCourses == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show classes:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showClasses == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              {params.showPrograms && params.showCourses && params.showClasses ? <Fragment>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Sigunps</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.classes.map((klass:any, t:number) => {
                          num += 1
                          var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                          var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var signupsUnique = klass.inmates.length
                          var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                          var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                          var active = _.filter(klass.inmates, (inmate:any) => inmate.active).length
                          var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var suspended = _.filter(klass.inmates, (inmate:any) => inmate.suspended).length
                          var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                          var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                          var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                          var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                          var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                          var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                          var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var incompletedUnique = _.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                          var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                          var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                          return (
                            <Fragment key={klass.className}>
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                <Table.Cell />
                                {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                              {klass.inmates.map((inmate:any, i:number) => {
                                num += 1
                                return (
                                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                    <Table.Cell />
                                    <Table.Cell />
                                    <Table.Cell />
                                    <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                    {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                    <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                  </Table.Row>
                                )
                              })}
                            </Fragment>
                          )
                        })
                      )
                    )}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={4} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalSignupsUnique()}</b> : <b>{this.totalSignups()}{this.totalSignupsUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalSignupsUnique()})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique()}</b> : <b>{this.totalEnrollment()}{this.totalEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalActive()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalSuspended()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalQuarantined()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalMissingUnique()}</b> : <b>{this.totalMissing()}{this.totalMissingUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalMissingUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique()}</b> : <b>{this.totalCompleted()}{this.totalCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalNotCompletedUnique()}</b> : <b>{this.totalNotCompleted()}{this.totalNotCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalNotCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalContEdUnique()}</b> : <b>{this.totalContEd()}{this.totalContEdUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalContEdUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                        {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={params.showSignups ? 13 : 12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.classes.map((klass:any, t:number) => {
                            num += 1
                            var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                            var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var signupsUnique = klass.inmates.length
                            var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            var enrolledHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled > 0).length
                            var active = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                            var activeHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var suspended = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                            var suspendedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var quarantined = _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
                            var quarantinedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var missing = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                            var missingHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var missingUnique = _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
                            var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                            var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                            var incompleted = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                            var incompletedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var incompletedUnique = _.filter(klass.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                            var contEd = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                            var contEdHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var contEdUnique = _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
                            return (
                              <Fragment key={klass.className}>
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                  <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                  <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                  <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                  <Table.Cell />
                                  {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                  <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                  <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                  <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                  <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                                {klass.inmates.map((inmate:any, i:number) => {
                                  num += 1
                                  return (
                                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                      <Table.Cell />
                                      <Table.Cell />
                                      <Table.Cell />
                                      <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                      {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                      <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                      <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                      <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                      <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                    </Table.Row>
                                  )
                                })}
                              </Fragment>
                            )
                          })
                        )
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={4} style={{textAlign:'right'}}>Totals</Table.Cell>
                            {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalSignupsUnique(facility)}</b> : <b>{this.facilityTotalSignups(facility)}{this.facilityTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalActive(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalSuspended(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalQuarantined(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalMissingUnique(facility)}</b> : <b>{this.facilityTotalMissing(facility)}{this.facilityTotalMissingUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalMissingUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility)}</b> : <b>{this.facilityTotalCompleted(facility)}{this.facilityTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalNotCompleted(facility)}</b> : <b>{this.facilityTotalNotCompleted(facility)}{this.facilityTotalNotCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalNotCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalContEdUnique(facility)}</b> : <b>{this.facilityTotalContEd(facility)}{this.facilityTotalContEdUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalContEdUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
              </Fragment> : params.showPrograms && params.showCourses ? <Fragment>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) => {
                        num += 1
                        var signups = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                        var signupsHighlight = _.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var signupsUnique = course.inmates.length
                        var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.enrolled > 0).length
                        var active = _.filter(course.inmates, (inmate:any) => inmate.active).length
                        var activeHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var suspended = _.filter(course.inmates, (inmate:any) => inmate.suspended).length
                        var suspendedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var quarantined = _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
                        var quarantinedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missing = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                        var missingHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missingUnique = _.filter(course.inmates, (inmate:any) => inmate.missing > 0).length
                        var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                        var incompleted = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                        var incompletedHighlight = _.any(_.filter(course.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var incompletedUnique = _.filter(course.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                        var contEd = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                        var contEdHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var contEdUnique = _.filter(course.inmates, (inmate:any) => inmate.contEd > 0).length
                        return (
                          <Fragment key={c}>
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                              <Table.Cell warning={program.highlight}>{c == 0 ? program.programName : ""}</Table.Cell>
                              <Table.Cell warning={course.highlight}>{course.courseName}</Table.Cell>
                              <Table.Cell />
                              {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                              <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                              <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                              <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                              <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                            {course.inmates.map((inmate:any, i:number) => {
                              num += 1
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                  <Table.Cell />
                                  <Table.Cell />
                                  <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                  {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                  <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            })}
                          </Fragment>
                        )
                      })
                    )}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCoursesSignupsUnique()}</b> : <b>{this.totalCoursesSignups()}{this.totalCoursesSignupsUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCoursesSignupsUnique()})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCoursesEnrollmentUnique()}</b> : <b>{this.totalCoursesEnrollment()}{this.totalCoursesEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCoursesEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalCoursesActive()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalCoursesSuspended()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalCoursesQuarantined()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCoursesMissingUnique()}</b> : <b>{this.totalCoursesMissing()}{this.totalCoursesMissingUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCoursesMissingUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCoursesCompletedUnique()}</b> : <b>{this.totalCoursesCompleted()}{this.totalCoursesCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCoursesCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCoursesNotCompletedUnique()}</b> : <b>{this.totalCoursesNotCompleted()}{this.totalCoursesNotCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCoursesNotCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCoursesContEdUnique()}</b> : <b>{this.totalCoursesContEd()}{this.totalCoursesContEdUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCoursesContEdUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                        {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={params.showSignups ? 12 : 11} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) => {
                          num += 1
                          var signups = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                          var signupsHighlight = _.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var signupsUnique = course.inmates.length
                          var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                          var enrolledHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var enrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.enrolled > 0).length
                          var active = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                          var activeHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var suspended = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                          var suspendedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var quarantined = _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
                          var quarantinedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missing = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                          var missingHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var missingUnique = _.filter(course.inmates, (inmate:any) => inmate.missing > 0).length
                          var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                          var completedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                          var incompleted = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                          var incompletedHighlight = _.any(_.filter(course.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var incompletedUnique = _.filter(course.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                          var contEd = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                          var contEdHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                          var contEdUnique = _.filter(course.inmates, (inmate:any) => inmate.contEd > 0).length
                          return (
                            <Fragment key={c}>
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                <Table.Cell warning={program.highlight}>{c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{course.courseName}</Table.Cell>
                                <Table.Cell />
                                {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                                <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                                <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                                <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                              {course.inmates.map((inmate:any, i:number) => {
                                num += 1
                                return (
                                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                    <Table.Cell />
                                    <Table.Cell />
                                    <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                    {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                    <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                    <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                    <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                  </Table.Row>
                                )
                              })}
                            </Fragment>
                          )
                        })
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                            {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCoursesTotalSignupsUnique(facility)}</b> : <b>{this.facilityCoursesTotalSignups(facility)}{this.facilityCoursesTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCoursesTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCoursesTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityCoursesTotalEnrollment(facility)}{this.facilityCoursesTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCoursesTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityCoursesTotalActive(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityCoursesTotalSuspended(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityCoursesTotalQuarantined(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCoursesTotalMissingUnique(facility)}</b> : <b>{this.facilityCoursesTotalMissing(facility)}{this.facilityCoursesTotalMissingUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCoursesTotalMissingUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCoursesTotalCompletedUnique(facility)}</b> : <b>{this.facilityCoursesTotalCompleted(facility)}{this.facilityCoursesTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCoursesTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCoursesTotalNotCompletedUnique(facility)}</b> : <b>{this.facilityCoursesTotalNotCompleted(facility)}{this.facilityCoursesTotalNotCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCoursesTotalNotCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCoursesTotalContEdUnique(facility)}</b> : <b>{this.facilityCoursesTotalContEd(facility)}{this.facilityCoursesTotalContEdUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCoursesTotalContEdUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
              </Fragment> : params.showPrograms ? <Fragment>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.programs.map((program:any, p:number) => {
                      num += 1
                      var signups = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                      var signupsHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var signupsUnique = program.inmates.length
                      var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                      var enrolledHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var enrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.enrolled > 0).length
                      var active = _.filter(program.inmates, (inmate:any) => inmate.active).length
                      var activeHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var suspended = _.filter(program.inmates, (inmate:any) => inmate.suspended).length
                      var suspendedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var quarantined = _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
                      var quarantinedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var missing = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                      var missingHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var missingUnique = _.filter(program.inmates, (inmate:any) => inmate.missing > 0).length
                      var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                      var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                      var incompleted = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                      var incompletedHighlight = _.any(_.filter(program.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var incompletedUnique = _.filter(program.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                      var contEd = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                      var contEdHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var contEdUnique = _.filter(program.inmates, (inmate:any) => inmate.contEd > 0).length
                      return (
                        <Fragment key={p}>
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell warning={program.highlight}>{program.programName}</Table.Cell>
                            <Table.Cell />
                            {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                            <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                            <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                            <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                          {program.inmates.map((inmate:any, i:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                <Table.Cell />
                                <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Fragment>
                      )
                    })}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={2} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b></b> : <b>{this.totalProgramsSignups()}{this.totalProgramsSignupsUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalProgramsSignupsUnique()})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b></b> : <b>{this.totalProgramsEnrollment()}{this.totalProgramsEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalProgramsEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramsActive()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramsSuspended()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramsQuarantined()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalProgramsMissingUnique()}</b> : <b>{this.totalProgramsMissing()}{this.totalProgramsMissingUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalProgramsMissingUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalProgramsCompletedUnique()}</b> : <b>{this.totalProgramsCompleted()}{this.totalProgramsCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalProgramsCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalProgramsNotCompletedUnique()}</b> : <b>{this.totalProgramsNotCompleted()}{this.totalProgramsNotCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalProgramsNotCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalProgramsContEdUnique()}</b> : <b>{this.totalProgramsContEd()}{this.totalProgramsContEdUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalProgramsContEdUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                        {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={params.showSignups ? 11 : 10} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.programs.map((program:any, p:number) => {
                        num += 1
                        var signups = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                        var signupsHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var signupsUnique = program.inmates.length
                        var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.enrolled > 0).length
                        var active = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                        var activeHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var suspended = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                        var suspendedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var quarantined = _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
                        var quarantinedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missing = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                        var missingHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missingUnique = _.filter(program.inmates, (inmate:any) => inmate.missing > 0).length
                        var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                        var incompleted = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                        var incompletedHighlight = _.any(_.filter(program.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var incompletedUnique = _.filter(program.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                        var contEd = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                        var contEdHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var contEdUnique = _.filter(program.inmates, (inmate:any) => inmate.contEd > 0).length
                        return (
                          <Fragment key={p}>
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                              <Table.Cell warning={program.highlight}>{program.programName}</Table.Cell>
                              <Table.Cell />
                              {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                              <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                              <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                              <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                              <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                            {program.inmates.map((inmate:any, i:number) => {
                              num += 1
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                  <Table.Cell />
                                  <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                  {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                  <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            })}
                          </Fragment>
                        )
                      })}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={2} style={{textAlign:'right'}}>Totals</Table.Cell>
                            {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{}</b> : <b>{this.facilityProgramsTotalSignups(facility)}{this.facilityProgramsTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityProgramsTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{}</b> : <b>{this.facilityProgramsTotalEnrollment(facility)}{this.facilityProgramsTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityProgramsTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityProgramsTotalActive(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityProgramsTotalSuspended(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityProgramsTotalQuarantined(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityProgramsTotalMissingUnique(facility)}</b> : <b>{this.facilityProgramsTotalMissing(facility)}{this.facilityProgramsTotalMissingUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityProgramsTotalMissingUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityProgramsTotalCompletedUnique(facility)}</b> : <b>{this.facilityProgramsTotalCompleted(facility)}{this.facilityProgramsTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityProgramsTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityProgramsTotalNotCompletedUnique(facility)}</b> : <b>{this.facilityProgramsTotalNotCompleted(facility)}{this.facilityProgramsTotalNotCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityProgramsTotalNotCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityProgramsTotalContEdUnique(facility)}</b> : <b>{this.facilityProgramsTotalContEd(facility)}{this.facilityProgramsTotalContEdUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityProgramsTotalContEdUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
              </Fragment> : <Fragment>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {(() => {
                      num += 1
                      var signups = _.reduce(data.completions, (acc:number, inmate:any) => acc + inmate.signups, 0)
                      var signupsHighlight = _.any(data.completions, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var signupsUnique = data.completions.length
                      var enrolled = _.reduce(data.completions, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                      var enrolledHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var enrolledUnique = _.filter(data.completions, (inmate:any) => inmate.enrolled > 0).length
                      var active = _.filter(data.completions, (inmate:any) => inmate.active).length
                      var activeHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var suspended = _.filter(data.completions, (inmate:any) => inmate.suspended).length
                      var suspendedHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var quarantined = _.filter(data.completions, (inmate:any) => inmate.quarantined).length
                      var quarantinedHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var missing = _.reduce(data.completions, (acc:number, inmate:any) => acc + inmate.missing, 0)
                      var missingHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var missingUnique = _.filter(data.completions, (inmate:any) => inmate.missing > 0).length
                      var completed = _.reduce(data.completions, (acc:number, inmate:any) => acc + inmate.completed, 0)
                      var completedHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var completedUnique = _.filter(data.completions, (inmate:any) => inmate.completed > 0).length
                      var incompleted = _.reduce(data.completions, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                      var incompletedHighlight = _.any(_.filter(data.completions, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var incompletedUnique = _.filter(data.completions, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                      var contEd = _.reduce(data.completions, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                      var contEdHighlight = _.any(_.filter(data.completions, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                      var contEdUnique = _.filter(data.completions, (inmate:any) => inmate.contEd > 0).length
                      return (
                        <Fragment>
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell />
                            {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                            <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                            <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                            <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                          {data.completions.map((inmate:any, i:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Fragment>
                      )
                    })()}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{}</b> : <b>{this.totalCompletionsSignups()}{this.totalCompletionsSignupsUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletionsSignupsUnique()})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{}</b> : <b>{this.totalCompletionsEnrollment()}{this.totalCompletionsEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletionsEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalCompletionsActive()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalCompletionsSuspended()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalCompletionsQuarantined()}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletionsMissingUnique()}</b> : <b>{this.totalCompletionsMissing()}{this.totalCompletionsMissingUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletionsMissingUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletionsCompletedUnique()}</b> : <b>{this.totalCompletionsCompleted()}{this.totalCompletionsCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletionsCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletionsNotCompletedUnique()}</b> : <b>{this.totalCompletionsNotCompleted()}{this.totalCompletionsNotCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletionsNotCompletedUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletionsContEdUnique()}</b> : <b>{this.totalCompletionsContEd()}{this.totalCompletionsContEdUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletionsContEdUnique()})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Inmate</span></div></Table.HeaderCell>
                        {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Active</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Suspended</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Currently Quarantined</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Deleted from Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Not Completed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cont. Ed</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={params.showSignups ? 10 : 9} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {(() => {
                        num += 1
                        var signups = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                        var signupsHighlight = _.any(facility.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var signupsUnique = facility.inmates.length
                        var enrolled = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.enrolled), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = _.filter(facility.inmates, (inmate:any) => inmate.enrolled > 0).length
                        var active = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.active, 0)
                        var activeHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var suspended = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.suspended, 0)
                        var suspendedHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var quarantined = _.filter(facility.inmates, (inmate:any) => inmate.quarantined).length
                        var quarantinedHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missing = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.missing, 0)
                        var missingHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var missingUnique = _.filter(facility.inmates, (inmate:any) => inmate.missing > 0).length
                        var completed = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(facility.inmates, (inmate:any) => inmate.completed > 0).length
                        var incompleted = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.noShow, 0)
                        var incompletedHighlight = _.any(_.filter(facility.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var incompletedUnique = _.filter(facility.inmates, (inmate:any) => (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.noShow > 0)).length
                        var contEd = _.reduce(facility.inmates, (acc:number, inmate:any) => acc + inmate.contEd, 0)
                        var contEdHighlight = _.any(_.filter(facility.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var contEdUnique = _.filter(facility.inmates, (inmate:any) => inmate.contEd > 0).length
                        return (
                          <Fragment>
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                              <Table.Cell />
                              {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                              <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={activeHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{active}</b></div></Table.Cell>
                              <Table.Cell warning={suspendedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{suspended}</b></div></Table.Cell>
                              <Table.Cell warning={quarantinedHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarantined}</b></div></Table.Cell>
                              <Table.Cell warning={missingHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{missingUnique}</b> : <b>{missing}{missingUnique == 0 ? null : <span style={{fontSize:10}}> ({missingUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell warning={incompletedHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{incompletedUnique}</div> : <div>{incompleted}{incompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({incompletedUnique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell warning={contEdHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <div>{contEdUnique}</div> : <div>{contEd}{contEdUnique == 0 ? null : <span style={{fontSize:10}}> ({contEdUnique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                            {facility.inmates.map((inmate:any, i:number) => {
                              num += 1
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                                  <Table.Cell warning={inmate.inmateHighlight}>{inmate.name}</Table.Cell>
                                  {params.showSignups ? <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.signups}</b></Table.Cell> : null}
                                  <Table.Cell warning={inmate.highlight} style={{textAlign:'right'}}><b>{inmate.enrolled}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.active ? inmate.active : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.suspended ? inmate.suspended : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><b>{inmate.quarantined}</b></Table.Cell>
                                  <Table.Cell warning={inmate.inmateHighlight} style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><b>{inmate.missing ? inmate.missing : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><b>{inmate.completed ? inmate.completed : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.incompleteHighlight} style={{textAlign:'right'}}><b>{inmate.incomplete ? inmate.incomplete : ''}</b></Table.Cell>
                                  <Table.Cell warning={inmate.contEdHighlight} style={{textAlign:'right'}}><b>{inmate.contEd ? inmate.contEd : ''}</b></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            })}
                          </Fragment>
                        )
                      })()}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={2} style={{textAlign:'right'}}>Totals</Table.Cell>
                            {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{}</b> : <b>{this.facilityCompletionsTotalSignups(facility)}{this.facilityCompletionsTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCompletionsTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{}</b> : <b>{this.facilityCompletionsTotalEnrollment(facility)}{this.facilityCompletionsTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCompletionsTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityCompletionsTotalActive(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityCompletionsTotalSuspended(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityCompletionsTotalQuarantined(facility)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCompletionsTotalMissing(facility)}</b> : <b>{this.facilityCompletionsTotalMissing(facility)}{this.facilityCompletionsTotalMissingUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCompletionsTotalMissingUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCompletionsTotalCompletedUnique(facility)}</b> : <b>{this.facilityCompletionsTotalCompleted(facility)}{this.facilityCompletionsTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCompletionsTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCompletionsTotalNotCompletedUnique(facility)}</b> : <b>{this.facilityCompletionsTotalNotCompleted(facility)}{this.facilityCompletionsTotalNotCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCompletionsTotalNotCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityCompletionsTotalContEdUnique(facility)}</b> : <b>{this.facilityCompletionsTotalContEd(facility)}{this.facilityCompletionsTotalContEdUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityCompletionsTotalContEdUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
              </Fragment>}
            </Container>
          )
        case 'demographics':
          var ethnicities = _.sortBy(_.compact(_.uniq(_.flatten(_.map(data.programs, (program:any) => _.map(program.courses, (course:any) => _.map(course.classes, (klass:any) => _.map(klass.inmates, (inmate:any) => inmate.ethnicityName ? inmate.ethnicityName : null))))))), (e:any) => e)
          var ethnicitiesDisplay = _.map(ethnicities, (ethnicity:string) => ethnicity.replace(/(.{20})..+/, "$1..."))
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Male / Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Male / Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Female / Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Female / Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Under 25 / Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Under 25 / Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>25 - 29 / Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>25 - 29 / Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>30 - 39 / Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>30 - 39 / Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>40+ / Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>40+ / Completed</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.programs.map((program:any, p:number) => {
                    num += 1
                    var signups = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0), 0), 0)
                    var signupsUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + klass.inmates.length, 0), 0)
                    var enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0), 0), 0)
                    var enrolledUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.enrolled).length, 0), 0)
                    var completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0), 0), 0)
                    var completedUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length, 0), 0)
                    var maleEnrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'M' ? inmate.enrolled : 0), 0), 0), 0)
                    var maleEnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'M').length, 0), 0)
                    var maleCompleted = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'M' ? inmate.completed : 0), 0), 0), 0)
                    var maleCompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'M' && inmate.completed > 0).length, 0), 0)
                    var femaleEnrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'F' ? inmate.enrolled : 0), 0), 0), 0)
                    var femaleEnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'F').length, 0), 0)
                    var femaleCompleted = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'F' ? inmate.completed : 0), 0), 0), 0)
                    var femaleCompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'F' && inmate.completed > 0).length, 0), 0)
                    var under25Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age < 25 ? inmate.enrolled : 0), 0), 0), 0)
                    var under25EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age < 25).length, 0), 0)
                    var under25Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age < 25 ? inmate.completed : 0), 0), 0), 0)
                    var under25CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age < 25 && inmate.completed > 0).length, 0), 0)
                    var _25to29Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 25 && inmate.age <= 29 ? inmate.enrolled : 0), 0), 0), 0)
                    var _25to29EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29).length, 0), 0)
                    var _25to29Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 25 && inmate.age <= 29 ? inmate.completed : 0), 0), 0), 0)
                    var _25to29CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29 && inmate.completed > 0).length, 0), 0)
                    var _30to39Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 30 && inmate.age <= 39 ? inmate.enrolled : 0), 0), 0), 0)
                    var _30to39EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39).length, 0), 0)
                    var _30to39Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 30 && inmate.age <= 39 ? inmate.completed : 0), 0), 0), 0)
                    var _30to39CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39 && inmate.completed > 0).length, 0), 0)
                    var over40Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 40 ? inmate.enrolled : 0), 0), 0), 0)
                    var over40EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 40).length, 0), 0)
                    var over40Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 40 ? inmate.completed : 0), 0), 0), 0)
                    var over40CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 40 && inmate.completed > 0).length, 0), 0)
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                        <Table.Cell>{program.programName}</Table.Cell>
                        {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{maleEnrolledUniq}</b> : <b>{maleEnrolled}{maleEnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({maleEnrolledUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{maleCompletedUniq}</b> : <b>{maleCompleted}{maleCompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({maleCompletedUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{femaleEnrolledUniq}</b> : <b>{femaleEnrolled}{femaleEnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({femaleEnrolledUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{femaleCompletedUniq}</b> : <b>{femaleCompleted}{femaleCompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({femaleCompletedUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{under25EnrolledUniq}</b> : <b>{under25Enrolled}{under25EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({under25EnrolledUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{under25CompletedUniq}</b> : <b>{under25Completed}{under25CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({under25CompletedUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_25to29EnrolledUniq}</b> : <b>{_25to29Enrolled}{_25to29EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({_25to29EnrolledUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_25to29CompletedUniq}</b> : <b>{_25to29Completed}{_25to29CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({_25to29CompletedUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_30to39EnrolledUniq}</b> : <b>{_30to39Enrolled}{_30to39EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({_30to39EnrolledUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_30to39CompletedUniq}</b> : <b>{_30to39Completed}{_30to39CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({_30to39CompletedUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{over40EnrolledUniq}</b> : <b>{over40Enrolled}{over40EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({over40EnrolledUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{over40CompletedUniq}</b> : <b>{over40Completed}{over40CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({over40CompletedUniq})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell style={{textAlign:'right'}}>Totals</Table.Cell>
                        {params.showSignups ? <Table.Cell warning={this.totalSignupsHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalSignups(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalSignupsUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell> : null}
                        <Table.Cell warning={this.totalEnrollmentHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique()}</b> : <b>{this.totalEnrollment()}{this.totalEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={this.totalCompletedHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique()}</b> : <b>{this.totalCompleted()}{this.totalCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolledMaleUnique()}</b> : <b>{this.totalEnrolledMale()}{this.totalEnrolledMaleUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolledMaleUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedMaleUnique()}</b> : <b>{this.totalCompletedMale()}{this.totalCompletedMaleUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedMaleUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolledFemaleUnique()}</b> : <b>{this.totalEnrolledFemale()}{this.totalEnrolledFemaleUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolledFemaleUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedFemaleUnique()}</b> : <b>{this.totalCompletedFemale()}{this.totalCompletedFemaleUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedFemaleUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolledUnder25Unique()}</b> : <b>{this.totalEnrolledUnder25()}{this.totalEnrolledUnder25Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolledUnder25Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnder25Unique()}</b> : <b>{this.totalCompletedUnder25()}{this.totalCompletedUnder25Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnder25Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolled25to29Unique()}</b> : <b>{this.totalEnrolled25to29()}{this.totalEnrolled25to29Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolled25to29Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompleted25to29Unique()}</b> : <b>{this.totalCompleted25to29()}{this.totalCompleted25to29Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompleted25to29Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolled30to39Unique()}</b> : <b>{this.totalEnrolled30to39()}{this.totalEnrolled30to39Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolled30to39Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompleted30to39Unique()}</b> : <b>{this.totalCompleted30to39()}{this.totalCompleted30to39Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompleted30to39Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolledOver40Unique()}</b> : <b>{this.totalEnrolledOver40()}{this.totalEnrolledOver40Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolledOver40Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedOver40Unique()}</b> : <b>{this.totalCompletedOver40()}{this.totalCompletedOver40Unique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedOver40Unique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell style={{textAlign:'right',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}>Total Population</Table.Cell>
                        <Table.Cell colSpan={params.showSignups ? 3 : 2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulation()}</b></div></Table.Cell>
                        <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulationMale()}</b></div></Table.Cell>
                        <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulationFemale()}</b></div></Table.Cell>
                        <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulationUnder25()}</b></div></Table.Cell>
                        <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulation25to29()}</b></div></Table.Cell>
                        <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulation30to39()}</b></div></Table.Cell>
                        <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulationOver40()}</b></div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                </Table.Body>
              </Table>
              {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Male / Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Male / Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Female / Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Female / Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Under 25 / Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Under 25 / Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>25 - 29 / Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>25 - 29 / Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>30 - 39 / Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>30 - 39 / Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>40+ / Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>40+ / Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={params.showSignups ? 16 : 15} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {facility.programs.map((program:any, p:number) => {
                      num += 1
                      var signups = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0), 0), 0)
                      var signupsUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + klass.inmates.length, 0), 0)
                      var enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0), 0), 0)
                      var enrolledUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.enrolled).length, 0), 0)
                      var completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0), 0), 0)
                      var completedUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length, 0), 0)
                      var maleEnrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'M' ? inmate.enrolled : 0), 0), 0), 0)
                      var maleEnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'M').length, 0), 0)
                      var maleCompleted = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'M' ? inmate.completed : 0), 0), 0), 0)
                      var maleCompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'M' && inmate.completed > 0).length, 0), 0)
                      var femaleEnrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'F' ? inmate.enrolled : 0), 0), 0), 0)
                      var femaleEnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'F').length, 0), 0)
                      var femaleCompleted = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.sex == 'F' ? inmate.completed : 0), 0), 0), 0)
                      var femaleCompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.sex == 'F' && inmate.completed > 0).length, 0), 0)
                      var under25Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age < 25 ? inmate.enrolled : 0), 0), 0), 0)
                      var under25EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age < 25).length, 0), 0)
                      var under25Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age < 25 ? inmate.completed : 0), 0), 0), 0)
                      var under25CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age < 25 && inmate.completed > 0).length, 0), 0)
                      var _25to29Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 25 && inmate.age <= 29 ? inmate.enrolled : 0), 0), 0), 0)
                      var _25to29EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29).length, 0), 0)
                      var _25to29Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 25 && inmate.age <= 29 ? inmate.completed : 0), 0), 0), 0)
                      var _25to29CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 25 && inmate.age <= 29 && inmate.completed > 0).length, 0), 0)
                      var _30to39Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 30 && inmate.age <= 39 ? inmate.enrolled : 0), 0), 0), 0)
                      var _30to39EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39).length, 0), 0)
                      var _30to39Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 30 && inmate.age <= 39 ? inmate.completed : 0), 0), 0), 0)
                      var _30to39CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 30 && inmate.age <= 39 && inmate.completed > 0).length, 0), 0)
                      var over40Enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 40 ? inmate.enrolled : 0), 0), 0), 0)
                      var over40EnrolledUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 40).length, 0), 0)
                      var over40Completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.age >= 40 ? inmate.completed : 0), 0), 0), 0)
                      var over40CompletedUniq = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.age >= 40 && inmate.completed > 0).length, 0), 0)
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                          <Table.Cell>{program.programName}</Table.Cell>
                          {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{maleEnrolledUniq}</b> : <b>{maleEnrolled}{maleEnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({maleEnrolledUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{maleCompletedUniq}</b> : <b>{maleCompleted}{maleCompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({maleCompletedUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{femaleEnrolledUniq}</b> : <b>{femaleEnrolled}{femaleEnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({femaleEnrolledUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{femaleCompletedUniq}</b> : <b>{femaleCompleted}{femaleCompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({femaleCompletedUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{under25EnrolledUniq}</b> : <b>{under25Enrolled}{under25EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({under25EnrolledUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{under25CompletedUniq}</b> : <b>{under25Completed}{under25CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({under25CompletedUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_25to29EnrolledUniq}</b> : <b>{_25to29Enrolled}{_25to29EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({_25to29EnrolledUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_25to29CompletedUniq}</b> : <b>{_25to29Completed}{_25to29CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({_25to29CompletedUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_30to39EnrolledUniq}</b> : <b>{_30to39Enrolled}{_30to39EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({_30to39EnrolledUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{_30to39CompletedUniq}</b> : <b>{_30to39Completed}{_30to39CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({_30to39CompletedUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{over40EnrolledUniq}</b> : <b>{over40Enrolled}{over40EnrolledUniq == 0 ? null : <span style={{fontSize:10}}> ({over40EnrolledUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{over40CompletedUniq}</b> : <b>{over40Completed}{over40CompletedUniq == 0 ? null : <span style={{fontSize:10}}> ({over40CompletedUniq})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell warning={this.facilityTotalSignupsHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalSignupsUnique(facility)}</b> : <b>{this.facilityTotalSignups(facility)}{this.facilityTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell warning={this.facilityTotalEnrollmentHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={this.facilityTotalCompletedHighlight(facility)} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility)}</b> : <b>{this.facilityTotalCompleted(facility)}{this.facilityTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolledMaleUnique(facility)}</b> : <b>{this.facilityTotalEnrolledMale(facility)}{this.facilityTotalEnrolledMaleUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolledMaleUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedMaleUnique(facility)}</b> : <b>{this.facilityTotalCompletedMale(facility)}{this.facilityTotalCompletedMaleUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedMaleUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolledFemaleUnique(facility)}</b> : <b>{this.facilityTotalEnrolledFemale(facility)}{this.facilityTotalEnrolledFemaleUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolledFemaleUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedFemaleUnique(facility)}</b> : <b>{this.facilityTotalCompletedFemale(facility)}{this.facilityTotalCompletedFemaleUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedFemaleUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolledUnder25Unique(facility)}</b> : <b>{this.facilityTotalEnrolledUnder25(facility)}{this.facilityTotalEnrolledUnder25Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolledUnder25Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnder25Unique(facility)}</b> : <b>{this.facilityTotalCompletedUnder25(facility)}{this.facilityTotalCompletedUnder25Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnder25Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolled25to29Unique(facility)}</b> : <b>{this.facilityTotalEnrolled25to29(facility)}{this.facilityTotalEnrolled25to29Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolled25to29Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompleted25to29Unique(facility)}</b> : <b>{this.facilityTotalCompleted25to29(facility)}{this.facilityTotalCompleted25to29Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompleted25to29Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolled30to39Unique(facility)}</b> : <b>{this.facilityTotalEnrolled30to39(facility)}{this.facilityTotalEnrolled30to39Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolled30to39Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompleted30to39Unique(facility)}</b> : <b>{this.facilityTotalCompleted30to39(facility)}{this.facilityTotalCompleted30to39Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompleted30to39Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolledOver40Unique(facility)}</b> : <b>{this.facilityTotalEnrolledOver40(facility)}{this.facilityTotalEnrolledOver40Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolledOver40Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedOver40Unique(facility)}</b> : <b>{this.facilityTotalCompletedOver40(facility)}{this.facilityTotalCompletedOver40Unique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedOver40Unique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right', borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}>Total Population</Table.Cell>
                          <Table.Cell colSpan={params.showSignups ? 3 : 2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulation(facility)}</b></div></Table.Cell>
                          <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulationMale(facility)}</b></div></Table.Cell>
                          <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulationFemale(facility)}</b></div></Table.Cell>
                          <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulationUnder25(facility)}</b></div></Table.Cell>
                          <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulation25to29(facility)}</b></div></Table.Cell>
                          <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulation30to39(facility)}</b></div></Table.Cell>
                          <Table.Cell colSpan={2} style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulationOver40(facility)}</b></div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
              ))}
              <Table compact celled striped unstackable className='slanted'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span><span style={{color:'rgb(33, 186, 69)'}}>Completed</span> / Enrolled</span></div></Table.HeaderCell>
                    {ethnicitiesDisplay.map((eth:any, i:number) => <Table.HeaderCell key={i} className='rotate' style={{border:0,width:40}}><div><span>{eth}</span></div></Table.HeaderCell>)}
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>No Ethnicity</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.programs.map((program:any, p:number) => {
                    num += 1
                    var signups = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0), 0), 0)
                    var signupsUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + klass.inmates.length, 0), 0)
                    var enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0), 0), 0)
                    var enrolledUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.enrolled).length, 0), 0)
                    var completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0), 0), 0)
                    var completedUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length, 0), 0)
                    var ethnicityEnrolledValues = _.map(ethnicities, (name:any) => { return { enrolled: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.ethnicityName == name ? (inmate.enrolled) : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == name).length, 0), 0) } })
                    var ethnicityCompletedValues = _.map(ethnicities, (name:any) => { return { completed: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.ethnicityName == name ? inmate.completed : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == name && inmate.completed > 0).length, 0), 0) } })
                    var nullEnrolled = { enrolled: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (!inmate.ethnicityName ? (inmate.enrolled) : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => !inmate.ethnicityName).length, 0), 0) }
                    var nullCompleted = { completed: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (!inmate.ethnicityName ? inmate.completed : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => !inmate.ethnicityName && inmate.completed > 0).length, 0), 0) }
                    return (
                      <Fragment key={p}>
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell>{program.programName}</Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                          {ethnicityEnrolledValues.map((eth:any, i:number) => <Table.Cell key={i} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{eth.unique}</b> : <b>{eth.enrolled}{eth.unique == 0 ? null : <span style={{fontSize:10}}> ({eth.unique})</span>}</b>}</div></Table.Cell>)}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nullEnrolled.unique}</b> : <b>{nullEnrolled.enrolled}{nullEnrolled.unique == 0 ? null : <span style={{fontSize:10}}> ({nullEnrolled.unique})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                        {(() => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                              <Table.Cell />
                              <Table.Cell style={{textAlign:'right',color:'rgb(33, 186, 69)'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                              {ethnicityCompletedValues.map((eth:any, i:number) => <Table.Cell key={i} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{eth.unique}</b> : <b>{eth.completed}{eth.unique == 0 ? null : <span style={{fontSize:10}}> ({eth.unique})</span>}</b>}</div></Table.Cell>)}
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nullCompleted.unique}</b> : <b>{nullCompleted.completed}{nullCompleted.unique == 0 ? null : <span style={{fontSize:10}}> ({nullCompleted.unique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                          )
                        })()}
                      </Fragment>
                    )
                  })}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell style={{textAlign:'right'}}>Totals</Table.Cell>
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique()}</b> : <b>{this.totalEnrollment()}{this.totalEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                        {ethnicities.map((eth:any, i:number) => <Table.Cell key={i} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolledEthnicityUnique(eth)}</b> : <b>{this.totalEnrolledEthnicity(eth)}{this.totalEnrolledEthnicityUnique(eth) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolledEthnicityUnique(eth)})</span>}</b>}</div></Table.Cell>)}
                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrolledEthnicityUnique(null)}</b> : <b>{this.totalEnrolledEthnicity(null)}{this.totalEnrolledEthnicityUnique(null) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrolledEthnicityUnique(null)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell />
                        <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique()}</b> : <b>{this.totalCompleted()}{this.totalCompletedUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique()})</span>}</b>}</div></Table.Cell>
                        {ethnicities.map((eth:any, i:number) => <Table.Cell key={i} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedEthnicityUnique(eth)}</b> : <b>{this.totalCompletedEthnicity(eth)}{this.totalCompletedEthnicityUnique(eth) == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedEthnicityUnique(eth)})</span>}</b>}</div></Table.Cell>)}
                        <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedEthnicityUnique(null)}</b> : <b>{this.totalCompletedEthnicity(null)}{this.totalCompletedEthnicityUnique(null) == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedEthnicityUnique(null)})</span>}</b>}</div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell style={{textAlign:'right',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}>Total Population</Table.Cell>
                        <Table.Cell style={{textAlign:'center',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulation()}</b></div></Table.Cell>
                        {ethnicities.map((eth:any, i:number) => <Table.Cell key={i} style={{textAlign:'right',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulationEthnicity(eth)}</b></div></Table.Cell>)}
                        <Table.Cell style={{textAlign:'right',borderBottom: data.facilities.length < 2 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalPopulationEthnicity(null)}</b></div></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                </Table.Body>
              </Table>
              {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span><span style={{color:'rgb(33, 186, 69)'}}>Completed</span> / Enrolled</span></div></Table.HeaderCell>
                      {ethnicities.map((eth:any, i:number) => <Table.HeaderCell key={i} className='rotate' style={{border:0,width:40}}><div><span>{eth}</span></div></Table.HeaderCell>)}
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={2 + ethnicities.length} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {facility.programs.map((program:any, p:number) => {
                      num += 1
                      var enrolled = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0), 0), 0)
                      var enrolledUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + klass.inmates.length, 0), 0)
                      var completed = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0), 0), 0)
                      var completedUnique = _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length, 0), 0)
                      var ethnicityEnrolledValues = _.map(ethnicities, (name:any) => { return { enrolled: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.ethnicityName == name ? (inmate.enrolled) : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == name).length, 0), 0) } })
                      var ethnicityCompletedValues = _.map(ethnicities, (name:any) => { return { completed: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (inmate.ethnicityName == name ? inmate.completed : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => inmate.ethnicityName == name && inmate.completed > 0).length, 0), 0) } })
                      var nullEnrolled = { enrolled: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (!inmate.ethnicityName ? (inmate.enrolled) : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => !inmate.ethnicityName).length, 0), 0) }
                      var nullCompleted = { completed: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.reduce(klass.inmates, (acc:number, inmate:any) => acc + (!inmate.ethnicityName ? inmate.completed : 0), 0), 0), 0), unique: _.reduce(program.courses, (a1:number, course:any) => a1 + _.reduce(course.classes, (a2:number, klass:any) => a2 + _.filter(klass.inmates, (inmate:any) => !inmate.ethnicityName && inmate.completed > 0).length, 0), 0) }
                      return (
                        <Fragment key={p}>
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell>{program.programName}</Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            {ethnicityEnrolledValues.map((eth:any, i:number) => <Table.Cell key={i} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{eth.unique}</b> : <b>{eth.enrolled}{eth.unique == 0 ? null : <span style={{fontSize:10}}> ({eth.unique})</span>}</b>}</div></Table.Cell>)}
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nullEnrolled.unique}</b> : <b>{nullEnrolled.enrolled}{nullEnrolled.unique == 0 ? null : <span style={{fontSize:10}}> ({nullEnrolled.unique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                          {(() => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                <Table.Cell />
                                <Table.Cell style={{textAlign:'right',color:'rgb(33, 186, 69)'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                {ethnicityCompletedValues.map((eth:any, i:number) => <Table.Cell key={i} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{eth.unique}</b> : <b>{eth.completed}{eth.unique == 0 ? null : <span style={{fontSize:10}}> ({eth.unique})</span>}</b>}</div></Table.Cell>)}
                                <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nullCompleted.unique}</b> : <b>{nullCompleted.completed}{nullCompleted.unique == 0 ? null : <span style={{fontSize:10}}> ({nullCompleted.unique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })()}
                        </Fragment>
                      )
                    })}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right'}}>Totals</Table.Cell>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                          {ethnicities.map((eth:any, i:number) => <Table.Cell key={i} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolledEthnicityUnique(facility, eth)}</b> : <b>{this.facilityTotalEnrolledEthnicity(facility, eth)}{this.facilityTotalEnrolledEthnicityUnique(facility, eth) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolledEthnicityUnique(facility, eth)})</span>}</b>}</div></Table.Cell>)}
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrolledEthnicityUnique(facility, null)}</b> : <b>{this.facilityTotalEnrolledEthnicity(facility, null)}{this.facilityTotalEnrolledEthnicityUnique(facility, null) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrolledEthnicityUnique(facility, null)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell />
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility)}</b> : <b>{this.facilityTotalCompleted(facility)}{this.facilityTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                          {ethnicities.map((eth:any, i:number) => <Table.Cell key={i} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedEthnicityUnique(facility, eth)}</b> : <b>{this.facilityTotalCompletedEthnicity(facility, eth)}{this.facilityTotalCompletedEthnicityUnique(facility, eth) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedEthnicityUnique(facility, eth)})</span>}</b>}</div></Table.Cell>)}
                          <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedEthnicityUnique(facility, null)}</b> : <b>{this.facilityTotalCompletedEthnicity(facility, null)}{this.facilityTotalCompletedEthnicityUnique(facility, null) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedEthnicityUnique(facility, null)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell style={{textAlign:'right', borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}>Total Population</Table.Cell>
                          <Table.Cell style={{textAlign:'center',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulation(facility)}</b></div></Table.Cell>
                          {ethnicities.map((eth:any, i:number) => <Table.Cell key={i} style={{textAlign:'right',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulationEthnicity(facility, eth)}</b></div></Table.Cell>)}
                          <Table.Cell style={{textAlign:'right',borderBottom: data.facilities.length == (f+1) ? 0 : '1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityTotalPopulationEthnicity(facility, null)}</b></div></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
              ))}
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'ethnic':
          if (params.selection == 'multiple') {
            var ethnicities = typeof params.ethnicities == 'string' ? [params.ethnicities] : params.ethnicities
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show courses:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showCourses == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show classes:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showClasses == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Ethnicities:</div><div style={{display:'inline-block',fontWeight:400}}>{_.map(ethnicities, (ethnicity:string) => _.any(options.ethnicities, (e:any) => e.value == ethnicity) && _.find(options.ethnicities, (e:any) => e.value == ethnicity).text).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      {params.showCourses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell> : null}
                      {params.showCourses == 'true' && params.showClasses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      {ethnicities.map((ethnicity:any) => (
                        <Fragment key={ethnicity}>
                          <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == ethnicity).text} Enrolled</span></div></Table.HeaderCell>
                          <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == ethnicity).text} Completed</span></div></Table.HeaderCell>
                        </Fragment>
                      ))}
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.programs.map((program:any, p:number) => {
                      if (!params.showCourses) {
                        num += 1
                        var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = program.inmates.length
                        var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                            <Table.Cell warning={program.highlight}>{program.programName}</Table.Cell>
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                            {ethnicities.map((ethnicity:any) => {
                              enrolled = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                              enrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity).length
                              completed = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                              completedUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0).length
                              return (
                                <Fragment key={ethnicity}>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                </Fragment>
                              )
                            })}
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      } else {
                        var courses = program.courses.map((course:any, c:number) => {
                          if (!params.showClasses) {
                            num += 1
                            var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            var enrolledHighlight = _.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var enrolledUnique = course.inmates.length
                            var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                            var completedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={c}>
                                <Table.Cell warning={program.highlight}>{c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{course.courseName}</Table.Cell>
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                {ethnicities.map((ethnicity:any) => {
                                  enrolled = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                  enrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity).length
                                  completed = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                                  completedUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0).length
                                  return (
                                    <Fragment key={ethnicity}>
                                      <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                      <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                    </Fragment>
                                  )
                                })}
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          } else {
                            return course.classes.map((klass:any, t:number) => {
                              num += 1
                              var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                              var enrolledHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var enrolledUnique = klass.inmates.length
                              var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                              var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                                  <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                  <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                  <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                  <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                  {ethnicities.map((ethnicity:any) => {
                                    enrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                    enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity).length
                                    completed = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                                    completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0).length
                                    return (
                                      <Fragment key={ethnicity}>
                                        <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                        <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                      </Fragment>
                                    )
                                  })}
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            })
                          }
                        })
                        num += 1
                        var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = program.inmates.length
                        var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                        var totals = (<Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                          <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>{program.programName} Subtotals</Table.Cell>
                          <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}></Table.Cell>
                          <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}></Table.Cell>
                          {ethnicities.map((ethnicity:any) => {
                            enrolled = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            enrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity).length
                            completed = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                            completedUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0).length
                            return (
                              <Fragment key={ethnicity}>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                              </Fragment>
                            )
                          })}
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>);
                        return courses.concat(totals)
                      }
                    })}
                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                      <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>Totals</Table.Cell>
                      <Table.Cell warning={this.totalEnrollmentHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell warning={this.totalCompletedHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      {ethnicities.map((ethnicity:any) => (
                        <Fragment key={ethnicity}>
                          <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalEthnicityEnrollment(ethnicity)}{this.totalEthnicityEnrollmentUnique(ethnicity) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEthnicityEnrollmentUnique(ethnicity)})</span>}</b></div></Table.Cell>
                          <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalEthnicityCompleted(ethnicity)}{this.totalEthnicityCompletedUnique(ethnicity) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEthnicityCompletedUnique(ethnicity)})</span>}</b></div></Table.Cell>
                        </Fragment>
                      ))}
                      <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 || true ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        {ethnicities.map((ethnicity:any) => (
                          <Fragment key={ethnicity}>
                            <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == ethnicity).text} Enrolled</span></div></Table.HeaderCell>
                            <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == ethnicity).text} Completed</span></div></Table.HeaderCell>
                          </Fragment>
                        ))}
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={5 + (ethnicities.length * 2)} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.classes.map((klass:any, t:number) => {
                            num += 1
                            var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            var enrolledHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var enrolledUnique = klass.inmates.length
                            var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                            var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                                <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                {ethnicities.map((ethnicity:any) => {
                                  enrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                  enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity).length
                                  completed = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                                  completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0).length
                                  return (
                                    <Fragment key={ethnicity}>
                                      <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                      <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                    </Fragment>
                                  )
                                })}
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })
                        )
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>Totals</Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalEnrollment(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalEnrollmentUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.facilityTotalCompleted(facility, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.facilityTotalCompletedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                            {ethnicities.map((ethnicity:any) => (
                              <Fragment key={ethnicity}>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityEthnicityTotalEnrollment(facility, ethnicity)}{this.facilityEthnicityTotalEnrollmentUnique(facility, ethnicity) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityEthnicityTotalEnrollmentUnique(facility, ethnicity)})</span>}</b></div></Table.Cell>
                                <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityEthnicityTotalCompleted(facility, ethnicity)}{this.facilityEthnicityTotalCompletedUnique(facility, ethnicity) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityEthnicityTotalCompletedUnique(facility, ethnicity)})</span>}</b></div></Table.Cell>
                              </Fragment>
                            ))}
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
          } else {
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show courses:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showCourses == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show classes:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showClasses == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Ethnicity:</div><div style={{display:'inline-block',fontWeight:400}}>{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      {params.showCourses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell> : null}
                      {params.showCourses == 'true' && params.showClasses == 'true' ? <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Percentage</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text} Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text} Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text} Percentage</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Non-{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text} Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Non-{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text} Completed</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Non-{_.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text} Percentage</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.programs.map((program:any, p:number) => {
                      if (!params.showCourses) {
                        num += 1
                        var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = program.inmates.length
                        var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var completedHighlight = _.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
                        var ethEnrolled = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var ethEnrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity).length
                        var ethCompleted = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var ethCompletedUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity && inmate.completed > 0).length
                        var nonEnrolled = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var nonEnrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity).length
                        var nonCompleted = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                        var nonCompletedUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity && inmate.completed > 0).length
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                            <Table.Cell warning={program.highlight}>{program.programName}</Table.Cell>
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={completedHighlight || enrolledHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{enrolled == 0 ? "0%" : `${Math.floor((completed / enrolled)*100)}%`}</b></div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{ethEnrolledUnique}</b> : <b>{ethEnrolled}{ethEnrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({ethEnrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{ethCompletedUnique}</b> : <b>{ethCompleted}{ethCompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({ethCompletedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{ethEnrolled == 0 ? "0%" : `${Math.floor((ethCompleted / ethEnrolled)*100)}%`}</b></div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nonEnrolledUnique}</b> : <b>{nonEnrolled}{nonEnrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({nonEnrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nonCompletedUnique}</b> : <b>{nonCompleted}{nonCompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({nonCompletedUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{nonEnrolled == 0 ? "0%" : `${Math.floor((nonCompleted / nonEnrolled)*100)}%`}</b></div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0,width:100}}></Table.Cell>
                          </Table.Row>
                        )
                      } else {
                        return <Fragment>
                          {program.courses.map((course:any, c:number) => {
                            if (!params.showClasses) {
                              num += 1
                              var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                              var enrolledHighlight = _.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var enrolledUnique = course.inmates.length
                              var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                              var completedHighlight = _.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                              var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                              var ethEnrolled = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                              var ethEnrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity).length
                              var ethCompleted = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                              var ethCompletedUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity && inmate.completed > 0).length
                              var nonEnrolled = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                              var nonEnrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity).length
                              var nonCompleted = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                              var nonCompletedUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity && inmate.completed > 0).length
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                                  <Table.Cell warning={program.highlight}>{c == 0 ? program.programName : ""}</Table.Cell>
                                  <Table.Cell warning={program.highlight}>{course.courseName}</Table.Cell>
                                  <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell warning={completedHighlight || enrolledHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{enrolled == 0 ? "0%" : `${Math.floor((completed / enrolled)*100)}%`}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{ethEnrolledUnique}</b> : <b>{ethEnrolled}{ethEnrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({ethEnrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{ethCompletedUnique}</b> : <b>{ethCompleted}{ethCompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({ethCompletedUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{ethEnrolled == 0 ? "0%" : `${Math.floor((ethCompleted / ethEnrolled)*100)}%`}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nonEnrolledUnique}</b> : <b>{nonEnrolled}{nonEnrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({nonEnrolledUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nonCompletedUnique}</b> : <b>{nonCompleted}{nonCompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({nonCompletedUnique})</span>}</b>}</div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{nonEnrolled == 0 ? "0%" : `${Math.floor((nonCompleted / nonEnrolled)*100)}%`}</b></div></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0,width:100}}></Table.Cell>
                                </Table.Row>
                              )
                            } else {
                              return course.classes.map((klass:any, t:number) => {
                                num += 1
                                var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                var enrolledHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var enrolledUnique = klass.inmates.length
                                var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                                var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                                var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                                var ethEnrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                var ethEnrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity).length
                                var ethCompleted = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                                var ethCompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity && inmate.completed > 0).length
                                var nonEnrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                var nonEnrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity).length
                                var nonCompleted = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                                var nonCompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity && inmate.completed > 0).length
                                return (
                                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                                    <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                    <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                    <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                    <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell warning={completedHighlight || enrolledHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{enrolled == 0 ? "0%" : `${Math.floor((completed / enrolled)*100)}%`}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{ethEnrolledUnique}</b> : <b>{ethEnrolled}{ethEnrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({ethEnrolledUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{ethCompletedUnique}</b> : <b>{ethCompleted}{ethCompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({ethCompletedUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{ethEnrolled == 0 ? "0%" : `${Math.floor((ethCompleted / ethEnrolled)*100)}%`}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nonEnrolledUnique}</b> : <b>{nonEnrolled}{nonEnrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({nonEnrolledUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{nonCompletedUnique}</b> : <b>{nonCompleted}{nonCompletedUnique == 0 ? null : <span style={{fontSize:10}}> ({nonCompletedUnique})</span>}</b>}</div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{nonEnrolled == 0 ? "0%" : `${Math.floor((nonCompleted / nonEnrolled)*100)}%`}</b></div></Table.Cell>
                                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0,width:100}}></Table.Cell>
                                  </Table.Row>
                                )
                              })
                            }
                          })}
                          {(() => { num += 1 })()}
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>{program.programName} Subtotals</Table.Cell>
                            <Table.Cell warning={this.totalProgramEnrollmentHighlight(program)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramEnrollmentUnique(program)}</b></div></Table.Cell>
                            <Table.Cell warning={this.totalProgramCompletedHighlight(program)} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramCompletedUnique(program)}</b></div></Table.Cell>
                            <Table.Cell warning={this.totalProgramCompletedHighlight(program)} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramEnrollmentUnique(program) == 0 ? "0%" : `${Math.floor((this.totalProgramCompletedUnique(program) / this.totalProgramEnrollmentUnique(program))*100)}%`}</b></div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramEthnicityCompletedUnique(program, params.ethnicity)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity) == 0 ? "0%" : `${Math.floor((this.totalProgramEthnicityCompletedUnique(program, params.ethnicity) / this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity))*100)}%`}</b></div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramNonEthnicityEnrollmentUnique(program, params.ethnicity)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramNonEthnicityCompletedUnique(program, params.ethnicity)}</b></div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalProgramNonEthnicityEnrollmentUnique(program, params.ethnicity) == 0 ? "0%" : `${Math.floor((this.totalProgramNonEthnicityCompletedUnique(program, params.ethnicity) / this.totalProgramNonEthnicityEnrollmentUnique(program, params.ethnicity))*100)}%`}</b></div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        </Fragment>
                      }
                    })}
                    {(() => { num += 1 })()}
                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                      <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right',borderTop:'2px solid black'}}>Totals</Table.Cell>
                      <Table.Cell warning={this.totalEnrollmentHighlight()} style={{textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell warning={this.totalCompletedHighlight()} style={{color:'rgb(33, 186, 69)',textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell warning={this.totalCompletedHighlight()} style={{color:'rgb(33, 133, 208)',textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? "0%" : `${Math.floor((this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false) / this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false))*100)}%`}</b></div></Table.Cell>
                      <Table.Cell style={{textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalEthnicityEnrollmentUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEthnicityEnrollmentUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalEthnicityCompletedUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalEthnicityCompletedUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? "0%" : `${Math.floor((this.totalEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) / this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false))*100)}%`}</b></div></Table.Cell>
                      <Table.Cell style={{textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalNonEthnicityEnrollmentUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalNonEthnicityEnrollmentUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalNonEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}</b> : <b>{this.totalNonEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)}{this.totalNonEthnicityCompletedUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? null : <span style={{fontSize:10}}> ({this.totalNonEthnicityCompletedUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})</span>}</b>}</div></Table.Cell>
                      <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right',borderTop:'2px solid black'}}><div style={{whiteSpace:'nowrap'}}><b>{this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? "0%" : `${Math.floor((this.totalNonEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) / this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false))*100)}%`}</b></div></Table.Cell>
                      <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 || true ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Completed</span></div></Table.HeaderCell>
                        {ethnicities.map((ethnicity:any) => (
                          <Fragment key={ethnicity}>
                            <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == ethnicity).text} Enrolled</span></div></Table.HeaderCell>
                            <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>{_.find(options.ethnicities, (e:any) => e.value == ethnicity).text} Completed</span></div></Table.HeaderCell>
                          </Fragment>
                        ))}
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={5 + (ethnicities.length * 2)} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.classes.map((klass:any, t:number) => {
                            num += 1
                            var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                            var enrolledHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var enrolledUnique = klass.inmates.length
                            var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                            var completedHighlight = _.any(_.filter(klass.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                            var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                                <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                                <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                                <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                                <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                <Table.Cell warning={completedHighlight} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                {ethnicities.map((ethnicity:any) => {
                                  enrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                                  enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity).length
                                  completed = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                                  completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0).length
                                  return (
                                    <Fragment key={ethnicity}>
                                      <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                                      <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{completedUnique}</b> : <b>{completed}{completedUnique == 0 ? null : <span style={{fontSize:10}}> ({completedUnique})</span>}</b>}</div></Table.Cell>
                                    </Fragment>
                                  )
                                })}
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })
                        )
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={1 + (params.showCourses ? 1 : 0) + (params.showCourses && params.showClasses ? 1 : 0)} style={{textAlign:'right'}}>Totals</Table.Cell>
                            <Table.Cell warning={this.facilityTotalEnrollmentHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={this.facilityTotalCompletedHighlight(facility)} style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalCompletedUnique(facility)}</b> : <b>{this.facilityTotalCompleted(facility)}{this.facilityTotalCompletedUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalCompletedUnique(facility)})</span>}</b>}</div></Table.Cell>
                            {ethnicities.map((ethnicity:any) => (
                              <Fragment key={ethnicity}>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityEthnicityTotalEnrollment(facility, ethnicity)}{this.facilityEthnicityTotalEnrollmentUnique(facility, ethnicity) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityEthnicityTotalEnrollmentUnique(facility, ethnicity)})</span>}</b></div></Table.Cell>
                                <Table.Cell style={{color:'rgb(33, 186, 69)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{this.facilityEthnicityTotalCompleted(facility, ethnicity)}{this.facilityEthnicityTotalCompletedUnique(facility, ethnicity) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityEthnicityTotalCompletedUnique(facility, ethnicity)})</span>}</b></div></Table.Cell>
                              </Fragment>
                            ))}
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                ))}
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
          }
        case 'participation':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                    {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Class Participants</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Course Participants</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Program Participants</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.programs.map((program:any, p:number) =>
                    program.courses.map((course:any, c:number) =>
                      course.classes.map((klass:any, t:number) => {
                        num += 1
                        var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                        var enrolledHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled).length
                        var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                        var signupsHighlight = _.any(klass.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)
                        var signupsUnique = klass.inmates.length
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                            <Table.Cell warning={program.highlight}>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                            <Table.Cell warning={course.highlight}>{t == 0 ? course.courseName : ""}</Table.Cell>
                            <Table.Cell warning={klass.highlight}>{klass.className}</Table.Cell>
                            {params.showSignups ? <Table.Cell warning={signupsHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                            <Table.Cell warning={enrolledHighlight} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                            <Table.Cell warning={klass.attendanceHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{klass.unique}</b> : <div><b>{klass.participated}</b>{klass.unique == 0 ? null : <span style={{fontSize:10}}> ({klass.unique})</span>}</div>}</div></Table.Cell>
                            <Table.Cell warning={course.attendanceHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{t == 0 ? <div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{course.unique}</b> : <div><b>{course.participated}</b>{course.unique == 0 ? null : <span style={{fontSize:10}}> ({course.unique})</span>}</div>}</div> : null}</div></Table.Cell>
                            <Table.Cell warning={program.attendanceHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{t == 0 && c == 0 ? <div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{program.unique}</b> : <div><b>{program.participated}</b>{program.unique == 0 ? null : <span style={{fontSize:10}}> ({program.unique})</span>}</div>}</div> : null}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })
                    )
                  )}
                  {(() => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                        <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                        {params.showSignups ? <Table.Cell warning={this.totalSignupsHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalSignupsUnique()}</b> : <b>{this.totalSignups()}{this.totalSignupsUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalSignupsUnique()})</span>}</b>}</div></Table.Cell> : null}
                        <Table.Cell warning={this.totalEnrollmentHighlight()} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.totalEnrollmentUnique()}</b> : <b>{this.totalEnrollment()}{this.totalEnrollmentUnique() == 0 ? null : <span style={{fontSize:10}}> ({this.totalEnrollmentUnique()})</span>}</b>}</div></Table.Cell>
                        <Table.Cell warning={data.attendanceHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{data.unique}</b> : <div><b>{data.participated}</b>{data.unique == 0 ? null : <span style={{fontSize:10}}> ({data.unique})</span>}</div>}</div></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                      </Table.Row>
                    )
                  })()}
                </Table.Body>
              </Table>
              {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                <Table compact celled striped unstackable className='slanted' key={facility.facilityAbbr}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                      {params.showSignups ? <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Signups</span></div></Table.HeaderCell> : null}
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Enrolled</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Class Participants</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Course Participants</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Program Participants</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:60}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={params.showSignups ? 8 : 7} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {facility.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.classes.map((klass:any, t:number) => {
                          num += 1
                          var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.enrolled, 0)
                          var enrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.enrolled).length
                          var signups = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.signups, 0)
                          var signupsUnique = klass.inmates.length
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={klass.className}>
                              <Table.Cell>{t == 0 && c == 0 ? program.programName : ""}</Table.Cell>
                              <Table.Cell>{t == 0 ? course.courseName : ""}</Table.Cell>
                              <Table.Cell>{klass.className}</Table.Cell>
                              {params.showSignups ? <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{signupsUnique}</b> : <b>{signups}{signupsUnique == 0 ? null : <span style={{fontSize:10}}> ({signupsUnique})</span>}</b>}</div></Table.Cell> : null}
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{enrolledUnique}</b> : <b>{enrolled}{enrolledUnique == 0 ? null : <span style={{fontSize:10}}> ({enrolledUnique})</span>}</b>}</div></Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{klass.unique}</b> : <div><b>{klass.participated}</b>{klass.unique == 0 ? null : <span style={{fontSize:10}}> ({klass.unique})</span>}</div>}</div></Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{t == 0 ? <div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{course.unique}</b> : <div><b>{course.participated}</b>{course.unique == 0 ? null : <span style={{fontSize:10}}> ({course.unique})</span>}</div>}</div> : null}</div></Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{t == 0 && c == 0 ?<div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{program.unique}</b> : <div><b>{program.participated}</b>{program.unique == 0 ? null : <span style={{fontSize:10}}> ({program.unique})</span>}</div>}</div> : null}</div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                          )
                        })
                      )
                    )}
                    {(() => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                          <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                          {params.showSignups ? <Table.Cell warning={this.facilityTotalSignupsHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalSignupsUnique(facility)}</b> : <b>{this.facilityTotalSignups(facility)}{this.facilityTotalSignupsUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalSignupsUnique(facility)})</span>}</b>}</div></Table.Cell> : null}
                          <Table.Cell warning={this.facilityTotalEnrollmentHighlight(facility)} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{this.facilityTotalEnrollmentUnique(facility)}</b> : <b>{this.facilityTotalEnrollment(facility)}{this.facilityTotalEnrollmentUnique(facility) == 0 ? null : <span style={{fontSize:10}}> ({this.facilityTotalEnrollmentUnique(facility)})</span>}</b>}</div></Table.Cell>
                          <Table.Cell warning={facility.attendanceHighlight} style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.unique == 'true' ? <b>{facility.unique}</b> : <div><b>{facility.participated}</b>{facility.unique == 0 ? null : <span style={{fontSize:10}}> ({facility.unique})</span>}</div>}</div></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })()}
                  </Table.Body>
                </Table>
              ))}
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'detailed-participation':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Ethnicity:</div><div style={{display:'inline-block',fontWeight:400}}>{_.any(options.ethnicities, (e:any) => e.value == params.ethnicity) ? _.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text : 'All'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showPrograms == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show courses:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showCourses == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Show classes:</div><div style={{display:'inline-block',fontWeight:400}}>{params.showClasses == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{border:0}} width={1}><div><span>#</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}} width={1}><div><span>SID</span></div></Table.HeaderCell>
                    {params.showNames ? <Table.HeaderCell style={{border:0}} width={4}><div><span>Student Name</span></div></Table.HeaderCell> : null}
                    {params.showPrograms ? <Table.HeaderCell style={{border:0}}><div><span>Programs</span></div></Table.HeaderCell> : null}
                    {params.showCourses ? <Table.HeaderCell style={{border:0}}><div><span>Courses</span></div></Table.HeaderCell> : null}
                    {params.showClasses ? <Table.HeaderCell style={{border:0}}><div><span>Classes</span></div></Table.HeaderCell> : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.inmates.map((inmate:any, i:number) => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                        <Table.Cell>{i+1}</Table.Cell>
                        <Table.Cell>{inmate.sid}</Table.Cell>
                        {params.showNames ? <Table.Cell>{inmate.name}</Table.Cell> : null}
                        {params.showPrograms ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any, p:number) => <div key={p}><div>{program.name}</div>{program.courses.map((course:any, c:number) => course.classes.map((klass:any, k:number) => c > 0 || k > 0 ? <br key={k} /> : null))}</div>)}</Table.Cell> : null}
                        {params.showCourses ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => program.courses.map((course:any, c:number) => <div key={c}><div>{course.name}</div>{course.classes.map((klass:any, k:number) => k > 0 ? <br key={k} /> : null)}</div>))}</Table.Cell> : null}
                        {params.showClasses ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => program.courses.map((course:any) => course.classes.map((klass:any, k:number) => <div key={k}>{klass}</div>)))}</Table.Cell> : null}
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
              {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => {
                num = 0
                return (facility.participants.length > 0 ? (
                  <Fragment key={f}>
                    <Table compact celled striped unstackable className='slanted'>
                      <Table.Header>
                        <Table.Row positive>
                          <Table.HeaderCell colSpan={3 + (params.showClasses ? 1 : 0) + (params.showCourses ? 1 : 0) + (params.showPrograms ? 1 : 0)} style={{borderTop:'2px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {facility.programs.map((program:any, p:number) => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={p}>
                              <Table.Cell><b>{program.programName}</b></Table.Cell>
                              <Table.Cell>{program.unique}</Table.Cell>
                              {params.showNames ? <Table.Cell /> : null}
                              {params.showPrograms ? <Table.Cell /> : null}
                              {params.showCourses ? <Table.Cell /> : null}
                              {params.showClasses ? <Table.Cell /> : null}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                    <Table compact celled striped unstackable className='slanted'>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} width={1}><div><span>#</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} width={1}><div><span>SID</span></div></Table.HeaderCell>
                          {params.showNames ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} width={4}><div><span>Student Name</span></div></Table.HeaderCell> : null}
                          {params.showPrograms ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}}><div><span>Programs</span></div></Table.HeaderCell> : null}
                          {params.showCourses ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}}><div><span>Courses</span></div></Table.HeaderCell> : null}
                          {params.showClasses ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}}><div><span>Classes</span></div></Table.HeaderCell> : null}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {facility.participants.map((inmate:any, i:number) => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                              <Table.Cell>{i+1}</Table.Cell>
                              <Table.Cell>{inmate.sid}</Table.Cell>
                              {params.showNames ? <Table.Cell>{inmate.name}</Table.Cell> : null}
                              {params.showPrograms ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => <div><div>{program.name}</div>{program.courses.map((course:any, c:number) => course.classes.map((klass:any, k:number) => c > 0 || k > 0 ? <br key={k} /> : null))}</div>)}</Table.Cell> : null}
                              {params.showCourses ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => program.courses.map((course:any) => <div><div>{course.name}</div>{course.classes.map((klass:any, k:number) => k > 0 ? <br key={k} /> : null)}</div>))}</Table.Cell> : null}
                              {params.showClasses ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => program.courses.map((course:any) => course.classes.map((klass:any) => <div>{klass}</div>)))}</Table.Cell> : null}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                  </Fragment>
                ) : null)
              })}
              {data.programs.length < 2 ? null : data.programs.map((program:any, p:number) => {
                num = 0
                return (
                  <Fragment key={p}>
                    <Table compact celled striped unstackable className='slanted'>
                      <Table.Header>
                        <Table.Row positive>
                          <Table.HeaderCell colSpan={3 + (params.showClasses ? 1 : 0) + (params.showCourses ? 1 : 0) + (params.showPrograms ? 1 : 0)} style={{borderTop:'2px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{program.programName}</b></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {program.facilities.map((facility:any, f:number) => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={f}>
                              <Table.Cell><b>{facility.facilityAbbr}</b></Table.Cell>
                              <Table.Cell>{facility.unique}</Table.Cell>
                              {params.showNames ? <Table.Cell /> : null}
                              {params.showCourses ? <Table.Cell /> : null}
                              {params.showClasses ? <Table.Cell /> : null}
                              {params.showPrograms ? <Table.Cell /> : null}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                    <Table compact celled striped unstackable className='slanted'>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} width={1}><div><span>#</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} width={1}><div><span>SID</span></div></Table.HeaderCell>
                          {params.showNames ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} width={4}><div><span>Student Name</span></div></Table.HeaderCell> : null}
                          {params.showCourses ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}}><div><span>Courses</span></div></Table.HeaderCell> : null}
                          {params.showClasses ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}}><div><span>Classes</span></div></Table.HeaderCell> : null}
                          {params.showPrograms ? <Table.HeaderCell style={{borderTop:'2px solid rgba(34,36,38,.1)',paddingTop:3,paddingBottom:3}} /> : null}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {program.participants.map((inmate:any, i:number) => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                              <Table.Cell>{i+1}</Table.Cell>
                              <Table.Cell>{inmate.sid}</Table.Cell>
                              {params.showNames ? <Table.Cell>{inmate.name}</Table.Cell> : null}
                              {params.showCourses ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => program.courses.map((course:any, c:number) => <div key={c}><div>{course.name}</div>{course.classes.map((klass:any, k:number) => k > 0 ? <br key={k} /> : null)}</div>))}</Table.Cell> : null}
                              {params.showClasses ? <Table.Cell style={{verticalAlign:'top'}}>{inmate.programs.map((program:any) => program.courses.map((course:any) => course.classes.map((klass:any, k:number) => <div key={k}>{klass}</div>)))}</Table.Cell> : null}
                              {params.showPrograms ? <Table.Cell /> : null}
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                  </Fragment>
                )
              })}
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'weekly-participation':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Quarterly Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : _.filter(params.programs, (id:any) => _.any(data.programs, (p:any) => p.programID == id))), (id:any) => _.find(data.programs, (p:any) => p.programID == id).programName).join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Uniques only:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unique == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Under 22:</div><div style={{display:'inline-block',fontWeight:400}}>{params.under22 == 'true' ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Unverified Diploma/HSE:</div><div style={{display:'inline-block',fontWeight:400}}>{params.unverified == 'true' ? 'Yes' : 'No'}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Body>
                  {_.chunk(data.quarters, 4).map((quarters:any, qs:number) => (
                    <Fragment key={qs}>
                      <Table.Row>
                        {_.times(quarters.length, (q:number) => (
                          <Fragment key={q}>
                            <Table.HeaderCell style={{padding:4,borderTop: qs == 0 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div><span>Week</span></div></Table.HeaderCell>
                            <Table.HeaderCell style={{padding:4,borderTop: qs == 0 ? 0 : '1px solid rgba(34,36,38,.1)'}}><div><span>Participated</span></div></Table.HeaderCell>
                          </Fragment>
                        ))}
                      </Table.Row>
                      <Table.Row positive>
                        {_.times(quarters.length, (q:number) => (
                          <Fragment key={q}>
                            <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>Quarter {(qs*4)+q+1}</b></Table.HeaderCell>
                          </Fragment>
                        ))}
                      </Table.Row>
                      {(() => {
                        var rows = []
                        var month = 0
                        var week = 0
                        while (_.any(quarters, (quarter:any) => quarter.months[month] ? quarter.months[month].weeks[week] : false)) {
                          num += 1
                          rows.push(
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={num}>
                              {_.times(quarters.length, (q:number) => (
                                <Fragment key={q}>
                                  <Table.Cell>{quarters[q].months[month] ? quarters[q].months[month].weeks[week] ? quarters[q].months[month].weeks[week].startDate : null : null}</Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}>{quarters[q].months[month] ? quarters[q].months[month].weeks[week] ? quarters[q].months[month].weeks[week].participated : null : null}</Table.Cell>
                                </Fragment>
                              ))}
                            </Table.Row>
                          )
                          if (_.any(quarters, (quarter:any) => quarter.months[month] ? quarter.months[month].weeks[week+1] : false)) {
                            week += 1
                          } else {
                            num += 1
                            rows.push(
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={num}>
                                {_.times(quarters.length, (q:number) => (
                                  <Fragment key={q}>
                                    <Table.Cell><b>{quarters[q].months[month].name} Average</b></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarters[q].months[month].average}</b></div></Table.Cell>
                                  </Fragment>
                                ))}
                              </Table.Row>
                            )

                            week = 0
                            month += 1
                          }
                        }
                        return rows
                      })()}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            {_.times(quarters.length, (q:number) => (
                              <Fragment key={q}>
                                <Table.Cell><b>Quarter {(qs*4)+q+1} Totals</b></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarters[q].participated}</b></div></Table.Cell>
                              </Fragment>
                            ))}
                          </Table.Row>
                        )
                      })()}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            {_.times(quarters.length, (q:number) => (
                              <Fragment key={q}>
                                <Table.Cell><b>Quarter {(qs*4)+q+1} Average</b></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{quarters[q].average}</b></div></Table.Cell>
                              </Fragment>
                            ))}
                          </Table.Row>
                        )
                      })()}
                      {(() => {
                        if (quarters.length == 4) {
                          if (quarters[0].months[0].name == 'January' && quarters[3].months[quarters[3].months.length-1].name == 'December') {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                <Table.Cell colSpan={2}><b>Calendar Year Total</b></Table.Cell>
                                <Table.Cell colSpan={2} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{_.reduce(quarters, (acc:number, quarter:any) => acc + quarter.participated, 0)}</b></div></Table.Cell>
                                <Table.Cell colSpan={2}><b>Calendar Year Average</b></Table.Cell>
                                <Table.Cell colSpan={2} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{Math.round(_.reduce(quarters, (acc:number, quarter:any) => acc + quarter.participated, 0) / 4)}</b></div></Table.Cell>
                              </Table.Row>
                            )
                          } else if (quarters[0].months[0].name == 'July' && quarters[3].months[quarters[3].months.length-1].name == 'June') {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                                <Table.Cell colSpan={2}><b>Fiscal Year Total</b></Table.Cell>
                                <Table.Cell colSpan={2} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{_.reduce(quarters, (acc:number, quarter:any) => acc + quarter.participated, 0)}</b></div></Table.Cell>
                                <Table.Cell colSpan={2}><b>Fiscal Year Average</b></Table.Cell>
                                <Table.Cell colSpan={2} style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{Math.round(_.reduce(quarters, (acc:number, quarter:any) => acc + quarter.participated, 0) / 4)}</b></div></Table.Cell>
                              </Table.Row>
                            )
                          }
                        }
                      })()}
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        default:
            return null
        }
    }
}

export default Quarterly
