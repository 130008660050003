import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../store'
import * as StaffStore from '../store/reducers/staff'
import * as DrawerStore from '../store/reducers/drawer'
import * as PanesStore from '../store/reducers/panes'
import * as DashboardStore from '../store/reducers/dashboard'
import * as AdminStore from '../store/reducers/admin'
import NotificationsWarningPDF from './reports/pdf/NotificationsWarning'
import { toast } from 'react-toastify'
import { format, getDay, addWeeks, addMonths, addYears } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Image,
  Menu,
  Icon,
  Dropdown,
  Form,
  Label,
  Modal,
  Button,
  Checkbox,
  Search,
  Select,
  Accordion,
  Table,
  Radio,
  Popup,
  Input
} from 'semantic-ui-react'
import {
  Menu as DropMenu,
  MenuItem,
  MenuButton,
  SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
const _ = require('underscore')

type NavBarProps =
    { mode: string, tab: string, notifications: any, surveys: any, facility: string, staff: any, modals: any, pdfs: any } &
    StaffStore.StaffState &
    typeof StaffStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    typeof DashboardStore.actionCreators &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class NavBar extends React.PureComponent<NavBarProps> {
    public componentDidMount() {
      var facility = this.props.facility
      if (!facility) {
        if (this.props.staff.admin) {
          facility = 'all'
        } else {
          facility = 'my'
        }
      }
      this.props.fetchNotifications(facility)
      this.props.fetchSurveys()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.loggedIn && this.props.loggedIn) {
        this.props.fetchSurveys()
      }
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public renderFunc(selectSearchResult:any, favorite:any, unfavorite:any, searchResults:any) {
      let style = {float:'right',backgroundColor:'#ddd',color:'white',marginTop:4,marginLeft:8}
      return function(data:any) {
        if (data.record == 0) {
          return (
            <div className="content">
              <div className="description"><a href='javascript:void(0)' onClick={() => { searchResults(null, data.entity) }}>Load more...</a></div>
            </div>
          )
        } else {
          return (
            <div className="content">
              <div style={{float:'right'}}>
                {data.favorite ? <Label circular icon='star' className='zero' onClick={() => { unfavorite(data.favorite, data.entity.toLowerCase()) }} style={style} /> : <Label circular icon='star outline' className='zero' onClick={() => { favorite(data.record, data.entity.toLowerCase()) }} style={{float:'right',backgroundColor:'#ddd',color:'white',marginTop:4,marginLeft:8,visibility:(['Inmate','Class','Template'].includes(data.entity) ? 'visible' : 'hidden')}} />}
                <Label circular onClick={() => { selectSearchResult(2, data) }} style={style}>2</Label>
                <Label circular onClick={() => { selectSearchResult(1, data) }} style={style}>1</Label>
              </div>
              <div className="title">{data.title}</div>
              <div className="description">{data.description}</div>
            </div>
          )
        }
      }
    }

    public render() {
      var notificationTypes = _.map(this.props.notifications.list, (n:any) => n.type)
      return (
        <Fragment>
          <Container fluid>
            {this.props.surveys.data.length > 0 ? <div className='survey' onClick={() => this.props.openSurvey(this.props.surveys.data[0])}>
              <div className='cta'>{this.props.surveys.data[0].cta}</div>
            </div> : null}
            <div>
              <Menu attached inverted color='blue' widths={this.props.staff.admin ? 10 : 9} style={{width:'100%',margin:0,backgroundColor:(this.props.staff!.theme ? this.props.staff!.theme.navBarBackgroundColor : false)}}>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { this.props.toggleDrawer('dashboard') }}><Icon name='dashboard' /> Dashboard</Menu.Item>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { this.props.toggleDrawer('inmates') }}><Icon name='user' /> Inmates</Menu.Item>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { ['templates','certificates'].includes(this.props.tab) ? this.props.toggleDrawer(this.props.tab) : this.props.toggleDrawer('classes') }}><Icon name='book' /> Classes</Menu.Item>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { this.props.toggleDrawer('schedules') }}><Icon name='calendar alternate' /> Schedules</Menu.Item>
                {this.props.staff.admin ? <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}}><Popup position='bottom center' wide='very' content={<div>
                  Coming Soon!<br/><br/>
                  Career Pathways is a comprehensive digital tool that helps students identify and navigate career goals, track participant progress along selected career paths, streamline program administration, and facilitate data-driven decision-making.<br/><br/>
                  This tool enhances our ability to:<br/>
                  • Personalize career pathways based on individual assessments and goals<br/>
                  • Efficiently allocate resources and instructors<br/>
                  • Identify trends and areas for program improvement<br/>
                  • Demonstrate program effectiveness through data-driven reporting<br/><br/>
                  With Career Pathways, we can more effectively guide participants through their career development journey, from initial assessment to post-release employment support.
                  </div>} trigger={<div><Icon name='suitcase' /> Career Pathways</div>} />
                </Menu.Item> : null}
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { this.props.toggleDrawer('reports') }}><Icon name='clipboard list' /> Reports</Menu.Item>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { this.props.toggleDrawer('admin') }}><Icon name='chess king' /> Admin</Menu.Item>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} onClick={() => { this.props.toggleDrawer('support') }}><Icon name='help' /> Help</Menu.Item>
                <Menu.Item style={{color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} className='search'>
                  <Search
                    category
                    placeholder='Search'
                    loading={this.props.modals.universal.data.loading}
                    onFocus={this.props.searchHistory}
                    onBlur={this.props.closeUniversalResults}
                    onResultSelect={(e:any, data:any) => {}}
                    onSearchChange={(e:any, data:any) => this.props.searchUniversalResults(data.value as string)}
                    resultRenderer={this.renderFunc(this.props.selectSearchResult.bind(this), this.props.favorite.bind(this), this.props.unfavorite.bind(this), this.props.searchUniversalResults.bind(this))}
                    results={this.props.modals.universal.data.results}
                    value={this.props.modals.universal.data.value||''}
                    open={this.props.modals.universal.open}
                    style={{display:'inline-block'}}
                    className='search'
                  />
                </Menu.Item>
                <Menu.Item style={{padding:0,color:(this.props.staff!.theme ? this.props.staff!.theme.navBarTextColor : false)}} position='right'>
                  <Image
                    avatar
                    spaced='right'
                    src={'/assets/user.png'}
                    style={{height:24,width:24}}
                  />
                  {this.props.loggedIn ? <Dropdown pointing='top right' text={this.props.staff!.displayName}>
                    <Dropdown.Menu className='nav'>
                      <Dropdown.Item
                        text='My profile'
                        icon='user'
                        onClick={() => this.props.toggleDrawer('profile')}
                      />
                      {this.props.favorites.length > 0 ? <Fragment>
                        <Dropdown.Header icon="star" content="Favorites" />
                        <Dropdown.Divider style={{margin:0}} />
                        {this.props.favorites.map((fav:any) => (
                          <Dropdown.Item key={fav.recordID}>
                            <Label circular className='red' onClick={() => { this.props.unfavorite(fav.recordID, fav.type) }} style={{float:'right',backgroundColor:'#ca1010 !important',width:20,marginTop:-4}}>
                              <Icon name='close' style={{paddingLeft:1}} />
                            </Label>
                            {fav.linkID != null ? null : <Label circular className='blue' onClick={() => { this.props.favoriteNav(fav, 1) }} style={{float:'right',backgroundColor:'#2185d0 !important',width:20,marginTop:-4}}>
                              <Icon name='arrow left' style={{paddingLeft:1}} />
                            </Label>}
                            {fav.linkID == null ? null : <Fragment>
                              <Label circular onClick={() => { this.props.favoriteNav(fav, 2) }} style={{float:'right',backgroundColor:'#ddd',marginTop:-4}}>2</Label>
                              <Label circular onClick={() => { this.props.favoriteNav(fav, 1) }} style={{float:'right',backgroundColor:'#ddd',marginTop:-4}}>1</Label>
                            </Fragment>}
                            <Icon name={fav.type == 'inmate' ? 'user' : fav.type == 'class' ? 'book' : fav.type == 'template' ? 'copy' : fav.type == 'schedules' ? 'calendar alternate' : fav.type == 'report' ? 'clipboard list' : 'linkify'} style={{verticalAlign:'middle'}} />
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{fav.title}</span>
                          </Dropdown.Item>
                        ))}
                      </Fragment> : null}
                      <Dropdown.Header icon="setting" content="Actions" />
                      <Dropdown.Divider style={{margin:0}} />
                      <Dropdown.Item onClick={() => this.props.logOut()} text='Logout' icon='power' />
                    </Dropdown.Menu>
                  </Dropdown> : null}
                </Menu.Item>
              </Menu>
            </div>
            {this.props.notifications.list.length > 0 ? <div><Menu attached inverted color='teal' widths={_.keys(_.groupBy(this.props.notifications.list, (n:any) => n.type)).length} style={{width:'100%',margin:0,backgroundColor:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarBackgroundColor : false)}}>
              {this.props.notifications.list.map((group:any, g:number) => {
                switch (group.type) {
                case 'approval':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='check square' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'approval')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Class Approvals`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'staff':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='calendar times' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'staff')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Expiring Staff`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'pending':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='clipboard list' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'pending')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Pending Attendance`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'orphan':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='search minus' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'orphan')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Orphaned TABEs`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'class':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='calendar alternate' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'class')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Classes Ending Soon`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'attendance':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='calendar minus' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'attendance')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Low Attendance`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'funding':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='dollar' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'funding')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Classes Without Funding`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'arrival':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='arrows alternate horizontal' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'arrival')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Recent Transfers`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'transcript':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='mail' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'transcript')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) Transcript Requests`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                case 'school':
                  return (
                    <Menu.Item key={g} style={{color:(this.props.staff!.theme ? this.props.staff!.theme.notificationBarTextColor : false)}}><Icon name='graduation' /><Dropdown pointing={`top ${(_.indexOf(notificationTypes, 'school')+1) / notificationTypes.length <= 0.5 ? 'left' : 'right'}`} text={`(${group.notifications.length}) School Requests`}>
                      <Dropdown.Menu className='nav'>
                        {group.notifications.map((notification:any, n:number) => (
                          <Dropdown.Item key={n} onClick={() => this.props.openNotificationModal(notification, group.type, n)}>
                            <span style={{marginRight:20,verticalAlign:'middle'}}>{notification.message}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown></Menu.Item>
                  )
                }
              })}
            </Menu></div> : null}
          </Container>
          <Modal
            open={this.props.modals.search.open}
            onClose={this.props.toggleSearchModal}
            size="small"
          >
            <Modal.Content>
                <Search
                  placeholder='Search Inmates'
                  loading={this.props.modals.notification.data ? this.props.modals.notification.data.loading : false}
                  onResultSelect={(e:any, data:any) => this.props.selectOrphanInmateNotification(this.props.modals.notification.data.recordID, data.result)}
                  onSearchChange={(e:any, data:any) => this.props.searchOrphanInmatesNotification(this.props.modals.notification.data.recordID, data.value as string)}
                  results={this.props.modals.notification.data ? this.props.modals.notification.data.inmates : []}
                  value={this.props.modals.notification.data ? (this.props.modals.notification.data.value||'') : ''}
                  style={{display:'inline-block'}}
                />
            </Modal.Content>
          </Modal>
          <Modal
            open={this.props.modals.notification.open}
            onClose={this.props.toggleNotificationModal}
            size="small"
          >
            <Modal.Header content={this.props.modals.notification.message} />
            {this.props.modals.notification.type == 'staff' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    label="Exp Date"
                    control={DatePicker}
                    selected={this.props.modals.notification.data.date ? new Date(this.props.modals.notification.data.date) : null}
                    onChange={(date:any) => this.props.updateNotificationData('date', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.modals.notification.data.expDate) && date <= addYears(new Date(),1)}
                  />
                  <Button positive content='Extend' style={{marginTop:20}} onClick={() => this.props.extendStaffNotification()} />
                  <Button color='blue' content='6' style={{marginTop:20}} onClick={() => this.props.updateNotificationData('date', format(addMonths(new Date(), 6), 'MM/dd/yyyy'))} />
                  <Button color='blue' content='12' style={{marginTop:20}} onClick={() => this.props.updateNotificationData('date', format(addYears(new Date(), 1), 'MM/dd/yyyy'))} />
                </Form.Group>
                <Form.Group>
                  <Checkbox
                    name="all"
                    label="Apply to all staff"
                    checked={this.props.modals.notification.data.all}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('all', data.checked)}
                    style={{marginLeft:5}}
                  />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'orphan' ? <Modal.Content>
              <Form>
                <Search
                  placeholder='Search Inmates'
                  loading={this.props.modals.notification.data.loading}
                  onResultSelect={(e:any, data:any) => this.props.selectOrphanInmateNotification(this.props.modals.notification.data.recordID, data.result)}
                  onSearchChange={(e:any, data:any) => this.props.searchOrphanInmatesNotification(this.props.modals.notification.data.recordID, data.value as string)}
                  results={this.props.modals.notification.data.inmates}
                  value={this.props.modals.notification.data.value||''}
                  style={{display:'inline-block'}}
                />
                <Button color={this.props.modals.notification.data.id == undefined || this.props.modals.notification.data.id == 0 ? "grey" : "green"} content='Assign' style={{marginLeft:15}} onClick={() => this.props.modals.notification.data.id == undefined || this.props.modals.notification.data.id == 0 ? null : this.props.assignOrphanNotification()} />
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'funding' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Funding Source'
                    name='fundingSource'
                    options={this.props.modals.notification.data.fundingOpts}
                    value={this.props.modals.notification.data.fundingSource}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('fundingSource', data.value as number)}
                    width={4}
                  />
                  <Button color={this.props.modals.notification.data.fundingSource ? "green" : "grey"} content='Assign' style={{marginTop:20,marginBottom:2,marginLeft:4}} onClick={() => this.props.modals.notification.data.fundingSource ? this.props.assignFundingNotification() : null} />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'instructor' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Instructor'
                    name='instructor'
                    options={this.props.modals.notification.data.instructorOpts}
                    value={this.props.modals.notification.data.instructorID}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('instructorID', data.value as number)}
                    width={6}
                  />
                  <Button color={this.props.modals.notification.data.instructorID == undefined || this.props.modals.notification.data.instructorID == 0 ? "grey" : "green"} content='Assign' style={{marginTop:20,marginLeft:4,marginBottom:2}} onClick={() => this.props.modals.notification.data.instructorID == undefined || this.props.modals.notification.data.instructorID == 0 ? null : this.props.assignInstructorNotification()} />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'class' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    label="Exp Date"
                    control={DatePicker}
                    selected={this.props.modals.notification.data.date ? new Date(this.props.modals.notification.data.date) : null}
                    onChange={(date:any) => this.props.updateNotificationData('date', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date > new Date(this.props.modals.notification.data.dateBegins)}
                  />
                  <Button positive content='Extend' style={{marginTop:20}} onClick={() => this.props.extendClassNotification()} />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    options={[{key:1,value:'1',text:'1'},{key:2,value:'2',text:'2'},{key:3,value:'3',text:'3'},{key:4,value:'4',text:'4'},{key:5,value:'5',text:'5'},{key:6,value:'6',text:'6'},{key:7,value:'7',text:'7'},{key:8,value:'8',text:'8'},{key:9,value:'9',text:'9'},{key:10,value:'10',text:'10'},{key:1,value:'11',text:'11'},{key:12,value:'12',text:'12'}]}
                    value={this.props.modals.notification.data.weeks}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('weeks', data.value)}
                    width={2}
                  />
                  <Button color='blue' content='Add Weeks' onClick={() => this.props.updateNotificationData('date', format(addWeeks(new Date(this.props.modals.notification.data.dateEnds), this.props.modals.notification.data.weeks), 'MM/dd/yyyy'))} />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'attendance' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Button color='blue' content='Print Warning Letter' onClick={() => this.props.generateWarning()} />
                  <NotificationsWarningPDF report={this.props.pdfs.warning} options={{}} />
                </Form.Group>
                <Form.Group style={{marginBottom:0}}>
                  <Button negative content="Suspend" onClick={() => this.props.suspendInmateNotification()} />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'pending' ? <Modal.Content>
              <Accordion styled style={{width:'100%',marginBottom:20}}>
                {this.props.modals.notification.data.pending.map((record:any) => (
                  <Fragment key={record.sessionID}>
                    <Accordion.Title active={this.props.modals.notification.data.selected.sessionID == record.sessionID} onClick={() => this.props.selectPendingNotification(record.sessionID)}><Icon name='user' style={{visibility:(record.rosterCount == 0 ? 'hidden' : 'visible')}} />[{this.props.modals.notification.data.classID}] {this.props.modals.notification.data.subTitle} — {this.dayOfWeek3(getDay(new Date(record.meetingDate)))}. {record.meetingDate} — {record.startTime} - {record.endTime}</Accordion.Title>
                    <Accordion.Content active={this.props.modals.notification.data.selected.sessionID == record.sessionID}>
                      {this.props.modals.notification.data.selected.sessionID == record.sessionID ? <Fragment>
                        <Table compact celled striped>
                          <Table.Header>
                            <Table.Row key='1'>
                              <Table.HeaderCell width={1}></Table.HeaderCell>
                              <Table.HeaderCell>Name</Table.HeaderCell>
                              <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                              <Table.HeaderCell width={1}>Attended</Table.HeaderCell>
                              <Table.HeaderCell width={1}>Excused</Table.HeaderCell>
                              <Table.HeaderCell width={1}>Unexcused</Table.HeaderCell>
                              <Table.HeaderCell>Other</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.props.modals.notification.data.selected.rosters.map((roster:any, i:number) => (
                              <Table.Row key={i}>
                                <Table.Cell>{i+1}</Table.Cell>
                                <Table.Cell>
                                  {this.props.mode == 'dual' ? <Popup
                                    hideOnScroll
                                    on='click'
                                    trigger={<div className='link' style={{whiteSpace:'normal'}}>{`${roster.lastName}, ${roster.firstName}`}</div>}
                                  >
                                    <Popup.Header>Open in pane</Popup.Header>
                                    <Popup.Content>
                                      <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, roster.inmateID) }}>1</Label>
                                      <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, roster.inmateID) }}>2</Label>
                                    </Popup.Content>
                                  </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, roster.inmateID) }}>{`${roster.lastName}, ${roster.firstName}`}</div>}
                                </Table.Cell>
                                <Table.Cell>{roster.sid}</Table.Cell>
                                <Table.Cell><Radio label='A' value='A' checked={roster.log == 'A'} onChange={(e:any,data:any) => { this.props.pendingChangeNotification(roster.recordID, data.value as string, this.props.modals.notification.data.selected) }} /></Table.Cell>
                                <Table.Cell><Radio label='X' value='X' checked={roster.log == 'X'} onChange={(e:any,data:any) => { this.props.pendingChangeNotification(roster.recordID, data.value as string, this.props.modals.notification.data.selected) }} /></Table.Cell>
                                <Table.Cell><Radio label='Z' value='Z' checked={roster.log == 'Z'} onChange={(e:any,data:any) => { this.props.pendingChangeNotification(roster.recordID, data.value as string, this.props.modals.notification.data.selected) }} /></Table.Cell>
                                <Table.Cell>
                                  <Radio checked={roster.log != 'A' && roster.log != 'X' && roster.log != 'Z'} />
                                  <Select name='otherLog' value={roster.log != 'A' && roster.log != 'X' && roster.log != 'Z' ? roster.log : 0} options={[{key:0,value:0,text:''}].concat(this.props.modals.notification.data.attendanceOpts)} onChange={(e:any, data:any) => this.props.pendingClassChangeNotification(roster.recordID, data.value as string, this.props.modals.notification.data.selected)} style={{marginLeft:10,width:'80%'}} />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                        <div><Input fluid placeholder="Notes" value={this.props.modals.notification.data.selected.notes||''} onChange={(e:any, data:any) => this.props.updatePendingNotesNotification(data.value.validate())} style={{marginBottom:10}} /></div>
                        <Button positive content='Save attendance' onClick={_.debounce(() => this.props.submitPendingNotification(this.props.modals.notification.data.selected), 10000, true)} />
                        {/*<Button negative content='Remove session' onClick={_.debounce(() => this.props.removeSession(record.sessionID), 10000, true)} />*/}
                      </Fragment> : null}
                    </Accordion.Content>
                  </Fragment>
                ))}
              </Accordion>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'transcript' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Checkbox
                    name="hs"
                    label="Mark HS info as verified"
                    checked={this.props.modals.notification.data.hs}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('hs', data.checked)}
                    style={{marginLeft:5}}
                  />
                </Form.Group>
                <Form.Group style={{marginBottom:0}}>
                  <Checkbox
                    name="diploma"
                    label="Mark Dipoma/HSE info as verified"
                    checked={this.props.modals.notification.data.diploma}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('diploma', data.checked)}
                    style={{marginLeft:5}}
                  />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            {this.props.modals.notification.type == 'school' ? <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='School name'
                    value={this.props.modals.notification.data.name}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('name', data.value)}
                    width={16}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Address'
                    value={this.props.modals.notification.data.address}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('address', data.value)}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='City'
                    value={this.props.modals.notification.data.city}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('city', data.value)}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='State'
                    value={this.props.modals.notification.data.state}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('state', data.value)}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Zip'
                    value={this.props.modals.notification.data.zip}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('zip', data.value)}
                    width={4}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Country'
                    value={this.props.modals.notification.data.country}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('country', data.value)}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Email'
                    value={this.props.modals.notification.data.email}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('email', data.value)}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Phone'
                    value={this.props.modals.notification.data.phone}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('phone', data.value)}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Fax'
                    value={this.props.modals.notification.data.fax}
                    onChange={(e:any, data:any) => this.props.updateNotificationData('fax', data.value)}
                    width={4}
                  />
                </Form.Group>
              </Form>
            </Modal.Content> : null}
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleNotificationModal}>Close</Button>
              {_.filter(this.props.notifications.list, (n:any) => n.type == this.props.modals.notification.type)[this.props.modals.notification.num-1] ? <Button color='blue' content='Previous' onClick={() => this.props.openNotificationModal(_.filter(this.props.notifications.list, (n:any) => n.type == this.props.modals.notification.type)[this.props.modals.notification.num-1], this.props.modals.notification.type, this.props.modals.notification.num-1)} /> : null}
              {_.filter(this.props.notifications.list, (n:any) => n.type == this.props.modals.notification.type)[this.props.modals.notification.num+1] ? <Button color='blue' content='Next' onClick={() => this.props.openNotificationModal(_.filter(this.props.notifications.list, (n:any) => n.type == this.props.modals.notification.type)[this.props.modals.notification.num+1], this.props.modals.notification.type, this.props.modals.notification.num+1)} /> : null}
              {this.props.modals.notification.type == 'orphan' ? <Fragment>
                <Button positive content="Open" onClick={() => { this.props.toggleNotificationModal(); this.props.toggleDrawer('dashboard') }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'school' ? <Fragment>
                <Button negative content="Remove" onClick={() => this.props.removeSchoolNotification()} />
                <Button positive content="Add" onClick={() => this.props.addSchoolNotification()} />
                <Button positive content="Open" onClick={() => { this.props.toggleNotificationModal(); this.props.toggleDrawer('admin') }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'staff' ? <Fragment>
                <Button negative content="Archive" onClick={() => this.props.archiveStaffNotification()} />
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchStaff(1, this.props.modals.notification.linkID) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchStaff(2, this.props.modals.notification.linkID) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'approval' ? <Fragment>
                <Button negative content="Deny" onClick={() => this.props.denyDashApprovalNotification()} />
                <Button negative content="Approve" onClick={() => this.props.approveDashApprovalNotification()} />
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(1, this.props.modals.notification.linkID, 2) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(2, this.props.modals.notification.linkID, 2) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'pending' ? <Fragment>
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(1, this.props.modals.notification.linkID, 2) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(2, this.props.modals.notification.linkID, 2) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'class' ? <Fragment>
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(1, this.props.modals.notification.linkID, 1) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(2, this.props.modals.notification.linkID, 1) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'attendance' ? <Fragment>
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchInmate(1, this.props.modals.notification.data.inmateID, 1) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchInmate(2, this.props.modals.notification.data.inmateID, 1) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'instructor' ? <Fragment>
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(1, this.props.modals.notification.linkID) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(2, this.props.modals.notification.linkID) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'funding' ? <Fragment>
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(1, this.props.modals.notification.linkID) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchClass(2, this.props.modals.notification.linkID) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'arrival' ? <Fragment>
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchInmate(1, this.props.modals.notification.linkID) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchInmate(2, this.props.modals.notification.linkID) }} />
              </Fragment> : null}
              {this.props.modals.notification.type == 'transcript' ? <Fragment>
                <Button positive content="Receive" onClick={() => this.props.receiveTranscriptNotification()} />
                <Button positive content="Open in Pane 1" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchInmate(1, this.props.modals.notification.linkID, 9) }} />
                <Button positive content="Open in Pane 2" onClick={() => { this.props.toggleNotificationModal(); this.props.fetchInmate(2, this.props.modals.notification.linkID, 9) }} />
              </Fragment> : null}
            </Modal.Actions>
          </Modal>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.staff, mode: state.panes.mode, tab: state.drawer.lastTab, notifications: state.dashboard.notifications, surveys: state.dashboard.surveys, facility: state.dashboard.facility, staff: state.staff.staff, modals: state.dashboard.modals, pdfs: state.dashboard.pdfs } },
  { ...StaffStore.actionCreators, ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...DashboardStore.actionCreators, ...AdminStore.actionCreators }
)(NavBar as any)
