import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import {
  Container,
  Form,
  Checkbox,
  Radio,
  Select,
  Grid
} from 'semantic-ui-react'

type RosterProps =
    DrawerStore.CreateModal &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class CreateClassOptions extends React.PureComponent<RosterProps> {
    public render() {
        return (
          <Container fluid>
            <Form>
              <Grid>
                <Grid.Column width={8}>
                  {this.props.data.facilitySex == "M" || this.props.data.facilitySex == "F" ? null : <Form.Group>
                    <Form.Field label="Gender:" width={8}/>
                    <Radio name="sex" label="M" value="M" checked={this.props.data.sex === "M"} onChange={(e:any, data:any) => this.props.dataChange('sex', data.value as string)} style={{marginLeft:7,marginRight:10}}  />
                    <Radio name="sex" label="F" value="F" checked={this.props.data.sex === "F"} onChange={(e:any, data:any) => this.props.dataChange('sex', data.value as string)} width={2} />
                  </Form.Group>}
                  <Form.Group>
                    <Form.Field width={8} label="Display on transcript">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="transcript"
                        checked={this.props.data.transcript}
                        onChange={(e:any, data:any) => this.props.dataChange('transcript', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Include class on movement list">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="movementList"
                        checked={this.props.data.movementList}
                        onChange={(e:any, data:any) => this.props.dataChange('movementList', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Include class on signin list">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="signinList"
                        checked={this.props.data.signinList}
                        onChange={(e:any, data:any) => this.props.dataChange('signinList', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Meet on holiday">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="meetOnHoliday"
                        checked={this.props.data.meetOnHoliday}
                        onChange={(e:any, data:any) => this.props.dataChange('meetOnHoliday', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Meet on furlough day">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="meetOnFurloughDay"
                        checked={this.props.data.meetOnFurloughDay}
                        onChange={(e:any, data:any) => this.props.dataChange('meetOnFurloughDay', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Title 1 class">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="title1"
                        checked={this.props.data.title1}
                        onChange={(e:any, data:any) => this.props.dataChange('title1', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="ABE">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="title1"
                        checked={this.props.data.abe}
                        onChange={(e:any, data:any) => this.props.dataChange('abe', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="No grade">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="noGrade"
                        checked={this.props.data.noGrade}
                        onChange={(e:any, data:any) => this.props.dataChange('noGrade', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="No attendance">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="noAttendance"
                        checked={this.props.data.noAttendance}
                        onChange={(e:any, data:any) => this.props.dataChange('noAttendance', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Group>
                    <Form.Field width={8} label="One time only" />
                    <Form.Field>
                      <Checkbox
                        name='oneTimeOnly'
                        checked={this.props.data.oneTimeOnly}
                        onChange={(e:any, data:any) => this.props.dataChange('oneTimeOnly', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Accumulate hours" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="accumulateHours"
                        checked={this.props.data.accumulateHours}
                        onChange={(e:any, data:any) => this.props.dataChange('accumulateHours', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  {/*<Form.Group>
                    <Form.Field width={8} label="Vocational" />
                    <Form.Field>
                      <Checkbox
                        name='vocational'
                        checked={this.props.data.vocational}
                        onChange={(e:any, data:any) => this.props.dataChange('vocational', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>*/}
                  <Form.Group>
                    <Form.Field width={8} label="Online" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.data.online}
                        onChange={(e:any, data:any) => this.props.dataChange('online', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Correspondence" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.data.correspondence}
                        onChange={(e:any, data:any) => this.props.dataChange('correspondence', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Post Secondary" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.data.postSecondary}
                        onChange={(e:any, data:any) => this.props.dataChange('postSecondary', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Workforce Development Diploma" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.data.workforceDiploma}
                        onChange={(e:any, data:any) => this.props.dataChange('workforceDiploma', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Workshop" />
                    <Form.Field>
                      <Checkbox
                        name='workshop'
                        checked={this.props.data.workshop}
                        onChange={(e:any, data:any) => this.props.dataChange('workshop', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Ignore diploma/HSE requirement">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="ignoreEdStatus"
                        checked={this.props.data.ignoreEdStatus}
                        onChange={(e:any, data:any) => this.props.dataChange('ignoreEdStatus', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Ignore hours requirement">
                    </Form.Field>
                    <Form.Field width={2}>
                      <Checkbox
                        name="ignoreHoursRqt"
                        checked={this.props.data.ignoreHoursRqt}
                        onChange={(e:any, data:any) => this.props.dataChange('ignoreHoursRqt', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="DoE" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="doe"
                        checked={this.props.data.doe}
                        onChange={(e:any, data:any) => this.props.dataChange('doe', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  {this.props.data.doe ? <Form.Group>
                    <Form.Field
                      control={Select}
                      label='DoE Facility'
                      name='doeFacilityID'
                      options={this.props.options.facilities}
                      value={this.props.data.doeFacilityID}
                      onChange={(e:any, data:any) => this.props.dataChange('doeFacilityID', data.value as number)}
                    />
                  </Form.Group> : null}
                </Grid.Column>
              </Grid>
            </Form>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState) => state.drawer.createClassM,
  DrawerStore.actionCreators
)(CreateClassOptions as any)