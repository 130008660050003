import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Icon,
  Header,
  Grid,
  Checkbox,
  Input,
  TextArea,
  Form,
  Button,
  Select,
  Message,
  Modal,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { course: PanesStore.Course, num: 1 | 2, print: boolean, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class CoursePane extends React.PureComponent<PaneProps> {
    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            {this.props.course.info.active ? null : <Message warning compact header='Archived' style={{float:'right',marginRight:10}} />}
            <Grid style={{marginBottom:0}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='building' /> {this.props.course.info.courseName} <span style={{fontSize:'0.8em',color:'#ccc',marginLeft:10,verticalAlign:'middle'}}>{this.props.course.info.recordID}</span></div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form style={{marginTop:20}}>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Course name'
                  name='Course name'
                  value={this.props.course.info.courseName||''}
                  onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'courseName', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Select}
                  label='Funding source'
                  placeholder='Funding source'
                  options={this.props.course.options.funding||[]}
                  value={this.props.course.info.fundingSource}
                  onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'fundingSource', data.value)}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='Required hours'
                  name='Required hours'
                  value={this.props.course.info.requiredHours||''}
                  onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'requiredHours', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              <Form.Field
                control={TextArea}
                label='Description'
                name='Description'
                value={this.props.course.info.description||''}
                onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'description', data.value)}
                className={this.props.print ? 'transparent' : ''}
              />
              <Form.Field
                control={TextArea}
                label='Course objectives'
                name='Course objectives'
                value={this.props.course.info.courseObjectives||''}
                onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'courseObjectives', data.value)}
                className={this.props.print ? 'transparent' : ''}
              />
              <Form.Field
                control={TextArea}
                label='Course outcomes'
                name='Course outcomes'
                value={this.props.course.info.courseOutcomes||''}
                onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'courseOutcomes', data.value)}
                className={this.props.print ? 'transparent' : ''}
              />
              {this.props.print ? null : <Fragment>
                <Button color='green' onClick={_.debounce(() => this.props.saveCourse(this.props.num), 10000, true)}>Save</Button>
                {this.props.course.info.active ? <Button color='black' content='Archive' onClick={_.debounce(() => this.props.archiveCourse(this.props.num), 10000, true)} /> : null}
                {!this.props.course.info.active ? <Button color='blue' content='Restore' onClick={_.debounce(() => this.props.restoreCourse(this.props.num), 10000, true)} /> : null}
                {this.props.staff.admin ? <Button color='red' onClick={_.debounce(() => this.props.removeCourse(this.props.num), 10000, true)}>Remove</Button> : null}
              </Fragment>}
            </Form>
            <Divider />
            <Header as='h3' style={{marginTop:10}}>Default Class Template Options</Header>
            <Form style={{marginTop:20}}>
              <Grid>
                <Grid.Column width={5}>
                  <Form.Group>
                    <Form.Field width={12} label="Display on transcript" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="transcript"
                        checked={this.props.course.info.transcript}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'transcript', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Include class on movement list" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="movementList"
                        checked={this.props.course.info.movementList}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'movementList', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Include class on signin list" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="signinList"
                        checked={this.props.course.info.signinList}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'signinList', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Meet on holiday" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="meetOnHoliday"
                        checked={this.props.course.info.meetOnHoliday}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'meetOnHoliday', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Meet on furlough day" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="meetOnFurloughDay"
                        checked={this.props.course.info.meetOnFurloughDay}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'meetOnFurloughDay', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Accumulate Hours" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="accumulateHours"
                        checked={this.props.course.info.accumulateHours}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'accumulateHours', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Reset Existing Class Templates" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="resetTemplates"
                        checked={this.props.course.info.resetTemplates}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'resetTemplates', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Group>
                    <Form.Field width={10} label="Title 1 class" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="title1"
                        checked={this.props.course.info.title1}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'title1', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={10} label="ABE class" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="abe"
                        checked={this.props.course.info.abe}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'abe', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={10} label="DoE" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="doe"
                        checked={this.props.course.info.doe}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'doe', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  {/*<Form.Group>
                    <Form.Field width={10} label="Vocational" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="vocational"
                        checked={this.props.course.info.vocational}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'vocational', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>*/}
                  <Form.Group>
                    <Form.Field width={10} label="Online" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.course.info.online}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'online', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={10} label="Correspondence" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.course.info.correspondence}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'correspondence', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={10} label="No grade" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="noGrade"
                        checked={this.props.course.info.noGrade}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'noGrade', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  {this.props.course.info.resetTemplates ? <Form.Group>
                    <Form.Field width={10} label="Reset Existing Classes" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="resetClasses"
                        checked={this.props.course.info.resetClasses}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'resetClasses', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group> : null}
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Group>
                    <Form.Field width={12} label="Workshop" />
                    <Form.Field>
                      <Checkbox
                        name='workshop'
                        checked={this.props.course.info.workshop}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'workshop', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Post Secondary" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.course.info.postSecondary}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'postSecondary', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Workforce Development Diploma" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={this.props.course.info.workforceDiploma}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'workforceDiploma', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Ignore diploma/HSE requirement" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="ignoreEdStatus"
                        checked={this.props.course.info.ignoreEdStatus}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'ignoreEdStatus', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="Ignore hours requirement" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="ignoreHoursRqt"
                        checked={this.props.course.info.ignoreHoursRqt}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'ignoreHoursRqt', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={12} label="No attendance" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="noAttendance"
                        checked={this.props.course.info.noAttendance}
                        onChange={(e:any, data:any) => this.props.editCourseField(this.props.num, 'noAttendance', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
                {this.props.print ? null : <Grid.Column style={{paddingTop:0}}>
                  <Button positive content='Save' onClick={_.debounce(() => this.props.saveCourse(this.props.num), 10000, true)} />
                </Grid.Column>}
              </Grid>
            </Form>
            <Modal
              open={this.props.course.modals.warning.open}
              onClose={() => this.props.toggleCourseWarningModal(this.props.num)}
              size="small"
            >
              <Modal.Header content={`Remove Course`} />
              <Modal.Content>
                <div>This course has active classes and/or waitlists under it. Are you sure you wish to <b>remove</b> it anyways?</div>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleCourseWarningModal(this.props.num)}>Close</Button>
                <Button
                  negative
                  content="Remove"
                  onClick={_.debounce(() => this.props.removeCourse(this.props.num, true), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.course.modals.archiveWarning.open}
              onClose={() => this.props.toggleCourseArchiveWarningModal(this.props.num)}
              size="small"
            >
              <Modal.Header content={`Remove Course`} />
              <Modal.Content>
                <div>This course has active classes and/or waitlists under it. Are you sure you wish to <b>archive</b> it anyways?</div>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleCourseArchiveWarningModal(this.props.num)}>Close</Button>
                <Button
                  color='black'
                  content="Archive"
                  onClick={_.debounce(() => this.props.archiveCourse(this.props.num, true), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { course: ownProps.course, num: ownProps.num, print: ownProps.options.print, staff: state.staff.staff } },
  PanesStore.actionCreators
)(CoursePane as any)