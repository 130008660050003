import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  Popup,
  Button,
  Header,
  Label,
  Accordion,
  Table,
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

type AttendanceProps =
    { class: PanesStore.Class, num: 1 | 2, mode: string, admin: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassAttendancePending extends React.PureComponent<AttendanceProps> {
    public componentDidMount() {
       this.props.fetchAttendancePending(this.props.num, this.props.class.info.recordID)
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const info = this.props.class.info
        const attendance = this.props.class.attendance
        const pending = attendance.pending
        const selectedP = attendance.selected.pending
        return (
          <Container fluid>
            {/*<Form>
              <Form.Group>
                <Form.Field
                  control={DatePicker}
                  label="Date From"
                  name="dateFrom"
                  placeholder="MM/DD/YYYY"
                  selected={attendance.dateFrom && attendance.dateFrom.length > 0 ? new Date(attendance.dateFrom) : null}
                  onChange={(date:any) => this.props.updateAttendanceFilter(this.props.num, 'dateFrom', date ? format(date, 'MM/dd/yyyy') : null)}
                  filterDate={(date:any) => date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                  width={4}
                />
                <Form.Field
                  control={DatePicker}
                  label="Date To"
                  name="dateTo"
                  placeholder="MM/DD/YYYY"
                  selected={attendance.dateTo && attendance.dateTo.length > 0 ? new Date(attendance.dateTo) : null}
                  onChange={(date:any) => this.props.updateAttendanceFilter(this.props.num, 'dateTo', date ? format(date, 'MM/dd/yyyy') : null)}
                  filterDate={(date:any) => date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                  width={4}
                />
              </Form.Group>
            </Form>*/}
            <Accordion styled style={{width:'100%',marginBottom:20}}>
              {pending.map((record:any) => (
                <Fragment key={record.sessionID}>
                  <Accordion.Title active={selectedP.sessionID == record.sessionID} onClick={() => this.props.selectPending(this.props.num, record.sessionID)}><Icon name='user' style={{visibility:(record.rosterCount == 0 ? 'hidden' : 'visible')}} />[{this.props.class.info.recordID}] {this.props.class.info.subTitle} — {this.dayOfWeek3(getDay(new Date(record.meetingDate)))}. {record.meetingDate} — {record.startTime} - {record.endTime}</Accordion.Title>
                  <Accordion.Content active={selectedP.sessionID == record.sessionID}>
                    {selectedP.sessionID == record.sessionID ? <Fragment>
                      <Table compact celled striped>
                        <Table.Header>
                          <Table.Row key='1'>
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Attended</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Excused</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Unexcused</Table.HeaderCell>
                            <Table.HeaderCell>Other</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {selectedP.rosters.map((roster:any, i:number) => (
                            <Table.Row key={i}>
                              <Table.Cell>{i+1}</Table.Cell>
                              <Table.Cell>
                                {this.props.mode == 'dual' ? <Popup
                                  hideOnScroll
                                  on='click'
                                  trigger={<div className='link' style={{whiteSpace:'normal'}}>{`${roster.lastName}, ${roster.firstName}`}</div>}
                                >
                                  <Popup.Header>Open in pane</Popup.Header>
                                  <Popup.Content>
                                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, roster.inmateID) }}>1</Label>
                                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, roster.inmateID) }}>2</Label>
                                  </Popup.Content>
                                </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, roster.inmateID) }}>{`${roster.lastName}, ${roster.firstName}`}</div>}
                              </Table.Cell>
                              <Table.Cell>{roster.sid}</Table.Cell>
                              <Table.Cell><Radio label='A' value='A' checked={roster.log == 'A'} onChange={(e:any,data:any) => { this.props.pendingChange(this.props.num, roster.recordID, data.value as string, selectedP, this.props.admin) }} /></Table.Cell>
                              <Table.Cell><Radio label='X' value='X' checked={roster.log == 'X'} onChange={(e:any,data:any) => { this.props.pendingChange(this.props.num, roster.recordID, data.value as string, selectedP, this.props.admin) }} /></Table.Cell>
                              <Table.Cell><Radio label='Z' value='Z' checked={roster.log == 'Z'} onChange={(e:any,data:any) => { this.props.pendingChange(this.props.num, roster.recordID, data.value as string, selectedP, this.props.admin) }} /></Table.Cell>
                              <Table.Cell>
                                <Radio checked={roster.log != 'A' && roster.log != 'X' && roster.log != 'Z'} />
                                <Select name='otherLog' value={roster.log != 'A' && roster.log != 'X' && roster.log != 'Z' ? roster.log : 0} options={[{key:0,value:0,text:''}].concat(this.props.class.options.attendance)} onChange={(e:any, data:any) => this.props.pendingClassChange(this.props.num, roster.recordID, data.value as string, selectedP, this.props.admin)} style={{marginLeft:10,width:'90%'}} />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                      <div><Input fluid placeholder="Notes" value={selectedP.notes||''} onChange={(e:any, data:any) => this.props.updatePendingNotes(this.props.num, data.value.validate())} style={{marginBottom:10}} /></div>
                      <Button positive content='Save attendance' onClick={_.debounce(() => this.props.submitPending(this.props.num, selectedP), 10000, true)} />
                      <Button negative content='Remove session' onClick={_.debounce(() => this.props.removeSession(this.props.num, record.sessionID), 10000, true)} />
                    </Fragment> : null}
                  </Accordion.Content>
                </Fragment>
              ))}
            </Accordion>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, mode: state.panes.mode, admin: state.staff.staff!.admin } },
  PanesStore.actionCreators
)(ClassAttendancePending as any)